const {handleActions} = require('redux-actions')
const {submissionPrefix} = require('redux-submission')
const castArray = require('cast-array')

const initialState = {
  categories: [],
  datapointErrors: [],
  signConfigByCategory: {},
  jurisdictionById: {},
  imageUpdatedAt: 0,
  imageEditorVisible: false
}

module.exports = handleActions({
  ['SUBMIT_saveDatapoint']: (state, action) => ({
    ...state,
    datapointErrors: [],
    saveStatus: 'Saving...',
  }),

  ['saveDatapoint']: (state, action) => {
    if (action.error) {
      return {
        ...state,
        datapointErrors: castArray(action.payload),
        saveStatus: 'Could not save'
      }
    }
    return {
      ...state,
      saveStatus: 'Saved',
      unsaved: false
    }
  },

  ['addNewSignToCurrentDatapoint']: (state, action) => {
    return {
      ...state,
      saveStatus: 'Unsaved changes',
      unsaved: true
    }
  },

  ['mutateCurrentDatapoint']: (state, action) => {
    return {
      ...state,
      saveStatus: 'Unsaved changes',
      unsaved: true
    }
  },

  ['resetDatapoint']: (state, action) => {
    return {
      ...state,
      saveStatus: 'Reset',
      unsaved: false
    }
  },

  ['SELECT_DATAPOINT']: (state, action) => {
    return {
      ...state,
      datapointErrors: [],
      saveStatus: '',
      unsaved: false
    }
  },

  ['CALCULATE_ZONES_PENDING']: (state, action) => {
    return {
      ...state,
      calcZonesPending: action.bool
    }
  },

  ['SAVE_AREA_PENDING']: (state, action) => {
    return {
      ...state,
      areaSavePending: action.bool
    }
  },

  ['SAVE_AREA_SUCCESS']: (state, action) => {
    return {
      ...state,
      areaSaveSuccess: action.bool
    }
  },

  ['FETCH_AREA_LIST_SUCCESS']: (state, action) => {
    return {
      ...state,
      areaList: action.areaList
    }
  },

  ['SET_PROCESSING_SIDE']: (state, action) => {
    return {
      ...state,
      saveProcessingSidePending: false,
      saveProcessingSideSuccess: false
    }
  },

  ['SAVE_PROCESSING_SIDE_PENDING']: (state, action) => {
    return {
      ...state,
      saveProcessingSidePending: action.bool
    }
  },

  ['SAVE_PROCESSING_SIDE_SUCCESS']: (state, action) => {
    return {
      ...state,
      saveProcessingSideSuccess: action.bool,
      saveProcessingSidePending: false
    }
  }


  // [showImageEditor]: (state, action) => ({
  //   ...state,
  //   imageEditorVisible: Boolean(action.payload)
  // }),

  // [uploadSignImage]: (state, action) => {
  //   return {
  //     ...state,
  //     imageEditorVisible: false,
  //     imageUpdatedAt: Date.now()
  //   }
  // },

}, initialState)
