const castArray = require('cast-array')
const ValidationError = require('error/validation')
const titleCase = require('title-case')

module.exports = function createValidationError (path, errors) {
  errors = castArray(errors)

  const validationError = ValidationError(errors)
  const errorId = path.map(titleCase).join(' / ')

  return Object.assign(validationError, {
    message: `[${errorId}]: ${validationError.message}`,
    id: errorId
  })
}
