const _ = require('lodash');

module.exports = {
  pad,
  objectInSortedKey,
  getSignsWithPriorityKey,
  getSignsOrderedInPriority,
  hierarchicalCombine,
  getJurisdictionById,
  getSignConfigById
}

function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

function objectInSortedKey(unordered) {
  if (unordered === undefined) return {};
  var ordered = {};
  Object.keys(unordered).sort().forEach(function(key) {
    if ((typeof unordered[key] == 'object') && (!_.isArray(unordered[key]))) {
      ordered[key] = objectInSortedKey(unordered[key]);
    } else {
      ordered[key] = unordered[key];
    }
  })
  return ordered;
}

function getSignsWithPriorityKey(signs, directionRight = false, signPriority = {}) {
  signs.map(sign => {
    if ((!directionRight) && (!sign.directionLeft)) return;
    if ((directionRight) && (!sign.directionRight)) return;
    let signCopy = {
      attributes: objectInSortedKey(sign["attributes"]),
      priority: sign.priority,
      signConfig: sign.signConfig
    };
    signPriority[pad(sign.priority, 2)] = signCopy;
  })

  return signPriority;
}

function getSignsOrderedInPriority(signPriority) {
  let keys = _.sortBy(Object.keys(signPriority));
  let response = [];
  keys.map(key => {
    response.push(signPriority[key]);
  })

  return response;
}

function hierarchicalCombine(jurisdiction, attributeName) {

  if (typeof jurisdiction !== 'object') return null;

  let queryJurisdiction = jurisdiction;

  var seperateAttributes = [];

  while (queryJurisdiction !== null) {
    if (queryJurisdiction.attributes[attributeName]) {
      seperateAttributes.push(queryJurisdiction.attributes[attributeName]);
    }

    if (queryJurisdiction.parent) {
      queryJurisdiction = getJurisdictionById(queryJurisdiction.parent.id);
    } else {
      queryJurisdiction = null;
    }
  }

  var mergedAttributes = [];
  _.forEachRight(seperateAttributes, (attribute) => {
    //console.log("MERGING ATTRIBUTE", attribute);
    mergedAttributes = _.union(mergedAttributes, attribute);
  });

  return mergedAttributes;
}

function getJurisdictionById(jurisdictionId, jurisdictions) {
  let index = _.findIndex(jurisdictions, { id: jurisdictionId });
  if (index === -1) return null;
  return jurisdictions[index];
}

function getSignConfigById(signConfigId, signConfigs) {
  let index = _.findIndex(signConfigs, { id: signConfigId });
  if (index === -1) return null;
  return signConfigs[index];
}