const t = require('tcomb')
const h = require('react-hyperscript')
const arrayToHash = require('array-to-hash')
const {propTypes} = require('tcomb-react')

const {ToggleButton} = require('../../../components/button')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')
const Mutator = require('../../../util/mutator')
const SubtypeSelect = require('./_subtype-select')

module.exports = MonthRangeComponent

MonthRangeComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Base.Type,
  data: SignAttrValue.MonthRange.Type
})

function MonthRangeComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  return h('div', rest, [
    h(SubtypeSelect, {
      label: 'Month Range Type',
      value: data.subtype,
      options: SignAttrValue.MonthRange.SUBTYPES,
      onChange: (subtype) => {
        mutator.onSetValue(['subtype'], subtype)
        if (subtype === 'ALL_MONTHS') {
          mutator.onSetValue(['months'], [])
        }
        if (subtype !== 'SPECIFIC_MONTHS_AND_YEAR') {
          mutator.onSetValue(['year'], null)
        }
      }
    }),

    data.subtype === 'ALL_MONTHS'
      ? undefined
      : MonthsOfYearValues(),
    data.subtype !== 'SPECIFIC_MONTHS_AND_YEAR'
      ? undefined
      : YearValues(),
  ])

  function MonthsOfYearValues () {
    const selectedMonths = arrayToHash(data.months)

    return h('div', {className: 'pa2'}, [
      ...SignAttrValue.MonthRange.MONTHS.map(function (month, index) {
        const isSelected = month in selectedMonths

        return h(ToggleButton, {
          selected: isSelected,
          onClick: () => isSelected
            ? mutator.onRemoveValue(['months'], data.months.indexOf(month))
            : mutator.onOrderedPushValue(['months'], month)
        }, month.substring(0, 3))
      })
    ])
  }

  function YearValues () {
    return h('div', {className: 'pa2'}, [
      ...SignAttrValue.MonthRange.YEARS.filter(r => r != null).map(function (year, index) {
        const isSelected = (year == data.year)
        return h(ToggleButton, {
          selected: isSelected,
          onClick: () => isSelected
            ? mutator.onSetValue(['year'], null)
            : mutator.onSetValue(['year'], year)
        }, year)
      })
    ])
  }
}
