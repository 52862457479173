const React = require('react')
const h = require('react-hyperscript')
const sf = require('sheetify')
const moment = require('moment');
const classes = require('classnames')
sf('./schedule.css');

const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const Schedule = React.createClass({
  render
})

function render() {
  const { props } = this;
  const { baseDate, interval, totalIntervals, schedule } = props.zone.schedule;

  let newSchedule = [];
  let nextStartDate, nextEndDate, nextBlock;
  for (let i = 0; i < schedule.length; i++) {
    nextStartDate = null;
    nextEndDate = null;
    nextBlock = null;
    let block = {
      ...schedule[i],
      startDate: moment.parseZone(baseDate).add(schedule[i].start * interval, 'minutes'),
      endDate: moment.parseZone(baseDate).add(schedule[i].end * interval, 'minutes')
    }

    // if schedule generated for more than a week, just show for the current week
    if ((schedule[i].end * interval) > 10080) block.endDate = moment.parseZone(baseDate).add(10080, 'minutes');
    if (schedule[i].start < 10080) {
      addBlock(block);      
    }
  }

  function addBlock(block) {
    if (block.endDate.date() !== block.startDate.date()) {
      let nextEndDate = block.endDate;
      let nextStartDate = block.startDate.clone().add(1, 'day').startOf('day');
      block.endDate = block.startDate.clone().endOf('day');
      nextBlock = {
        ...block,
        startDate: nextStartDate,
        endDate: nextEndDate
      }
      newSchedule.push(block);
      addBlock(nextBlock);
    }
    else if (!block.startDate.isSame(block.endDate)) newSchedule.push(block);
  }

  let blocksByDay = newSchedule.reduce((acc, block) => {
    let dayOfWeek = block.startDate.format('ddd').toUpperCase();
    if (!acc[dayOfWeek]) acc[dayOfWeek] = [block];
    else acc[dayOfWeek].push(block);
    return acc;
  }, {});

  return h('div', {className: 'schedule-component'}, [
    h('div', {className: 'header'}, 'Zone Schedule'),
    h('div', {className: 'close', onClick: props.close}, 'close'),
    h('div', {className: 'schedule-container'}, [
      h('div', {className: 'week-label-container'}, [
        days.map(day => h('div', {className: 'day-label', key: day}, day))
      ]),
      h('div', {className: 'week-grid-container'}, [
        days.map(day => h('div', {className: 'day-column', key: day}, [
          h(Day, {day, blocks: blocksByDay[day]})
        ]))
      ])
    ])
  ])
}

function Day({
  day,
  blocks
}) {
  return h('div', {className: 'day-schedule'}, [
    blocks.map(block => h(Block, {block: block, key: day + block.start}))
  ])
}

function Block({
  block
}) {
  let startTime = block.startDate.format('LT')
  let endTime = block.endDate.format('LT')
  let parking = block.parking.split('_').join(' ')
  let category = block.conditions && block.conditions.category ? block.conditions.category.split('_').join(' ') : null
  if (category === parking) category = null
  return h('div', {className: classes('schedule-block-container', block.parking.toLowerCase())}, [
    h('div', {className: 'block-time'}, startTime),
    h('div', {className: 'block-time'}, endTime),
    h('div', {className: 'block-parking'}, parking),
    category ? h('div', {className: 'block-conditions'}, category) : null
  ])
}

module.exports = Schedule