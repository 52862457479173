const h = require('react-hyperscript')
const React = require('react')
const classes = require('classnames')
const t = require('tcomb')
const {propTypes} = require('tcomb-react')
const sf = require('sheetify')

const {Button} = require('../button')
const Cropper = require('react-cropper').default
const ImageControls = require('../image-controls')

const className = sf('./image-editor.css')
const noop = () => {}

module.exports = React.createClass({
  propTypes: propTypes({
    src: t.String,
    viewOnly: t.Boolean,
    onCrop: t.Function,
    onClose: t.Function,
    pending: t.Boolean
  }),
  getDefaultProps: () => ({
    viewOnly: false,
    pending: false,
    onCrop: noop,
    onClose: noop
  }),

  getInitialState,
  onCrop,
  save,
  reset,
  render
})

const CROPPER_VIEW_ONLY_OPTIONS = {
  autoCrop: false,
  dragMode: 'move',
  background: false,
  highlight: false
}
const CROPPER_NORMAL_OPTIONS = {
  autoCrop: false
}

function getInitialState () {
  return {
    imageMeta: ImageControls.createProperties()
  }
}

function onCrop (event) {
  this.cropperData = event.detail
}

function save () {
  if (!this.cropper) return

  this.props.onCrop({
    meta: this.cropperData,
    uri: this.cropper.getCroppedCanvas().toDataURL('image/jpeg')
  })
}

function reset () {
  this.setState(getInitialState())
  if (this.cropper) this.cropper.reset()
}

function render () {
  const meta = this.state.imageMeta

  return h('div', {
    className: classes(className, 'editor-container mh-60')
  }, [
    h('div', {className: 'cropper-widget-container'}, [
      h(Cropper, {
        ref: (cropper) => { this.cropper = cropper },
        crop: this.onCrop,
        src: this.props.src,
        zoomTo: meta.zoom / 100,
        rotateTo: meta.degrees,
        ...(this.props.viewOnly ? CROPPER_VIEW_ONLY_OPTIONS : CROPPER_NORMAL_OPTIONS)
      })
    ]),

    h(ImageControls, {
      className: 'mt1 image-controls',
      ...meta,
      onChange: (data) => this.setState({imageMeta: data})
    }),

    h(Button, {
      onClick: () => this.reset()
    }, 'Reset'),

    this.props.viewOnly
      ? undefined
      : h('i.mt2', null, 'Hint: double click to toggle between "dragging" and "cropping" mode.'),

    this.props.viewOnly ? undefined : h('div', {
      className: 'mt4 flex w-100'
    }, [
      h(Button, {
        className: 'flex-auto',
        disabled: this.props.pending,
        onClick: this.props.onClose
      }, 'Cancel'),
      h(Button, {
        onClick: this.save,
        disabled: this.props.pending,
        className: 'flex-auto',
        theme: 'primary'
      }, this.props.pending ? h('i.fa.fa-circle-o-notch.fa-spin') : 'Save')
    ])
  ])
}
