const _ = require('lodash');
const monthRange = require('./monthRange');
const dayRange = require('./dayRange');
const timeRange = require('./timeRange');
const dateUtil = require('./dateUtil');
const periods = require('./periods');
const Executor = require('./executor');

function runJob(sign, signConfig, jurisdiction, userProfile, script) {
  return new Promise((resolve, reject) => {

    if (!signConfig.processingScripts) return null;
    if (!signConfig.processingScripts[script]) return null;

    var context = {};

    context.state = {};
    context.signConfig = signConfig;
    context.jurisdiction = jurisdiction;
    context.userProfile = userProfile
    context.sign = sign;

    var libraries = {
      Promise: Promise,
      _: _,
      monthRange: monthRange,
      timeRange: timeRange,
      dayRange: dayRange,
      dateUtil: dateUtil,
      periods: periods
    }

    var job = new Executor();
    job.once('completed', resolve);
    job.once('error', reject);

    job.run(signConfig.processingScripts[script], context, libraries, job);
  });
}

module.exports = runJob;