var _ = require('lodash');
var moment = require('moment');


var exports = module.exports;

exports.stringify = function(range) {

  if (!_.isObject(range)) return "";

  switch(range.subtype) {
    case 'WEEKDAYS_OF_WEEK':
      var output = (range.days & 0x01) ? "Mon " : "";
      output += (range.days & 0x02) ? "Tue " : "";
      output += (range.days & 0x04) ? "Wed " : "";
      output += (range.days & 0x08) ? "Thu " : "";
      output += (range.days & 0x10) ? "Fri " : "";
      output += (range.days & 0x20) ? "Sat " : "";
      output += (range.days & 0x40) ? "Sun " : "";
      return output;

      case 'WEEKDAY_OF_MONTH':
      var output = "";
      range.weekDayOfMonth.map(day => {
        output += day.weekDay + "#Week" + day.dayNumber + " ";
      });
      return output;
    default:
      return "";
  }
};
