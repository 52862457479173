const h = require('react-hyperscript')
const t = require('tcomb')
const {propTypes} = require('tcomb-react')
const {walkSignAttributes} = require('../../schema')

const {Sign, SignConfig} = require('../../schema')
const {ArrayValue, PrimitiveValue, StructureValue} = require('./editor-value')
const dotProp = require('dot-prop-immutable')
import { template } from 'lodash';

module.exports = SignAttributeEditorComponent

SignAttributeEditorComponent.propTypes = propTypes({
  sign: Sign.Type,
  signConfig: SignConfig.Type,
  onChange: t.Function
})

function SignAttributeEditorComponent ({
  sign,
  signConfig,
  onChange,
  ...rest
}) {
  return h('div', rest, [
    walkSignAttributes(sign, signConfig, walk)
      .map(renderTopLevelAttribute)
  ])

  function renderTopLevelAttribute (element, index) {
    return h('div', {
      key: index,
      className: 'pv2 ph3 mt2 ba b--black-40'
    }, element)
  }

  function walk ({
    yieldChildren,
    type,
    sign,
    signConfig,
    attributeConfig,
    attributeValue,
    path
  }) {
    const Component = type === 'Array' ? ArrayValue
      : type === 'Structure' ? StructureValue
      : PrimitiveValue

    if (attributeConfig.showDependency) {
      let dependentFieldDetails = attributeConfig.showDependency.split('=>');
      let fields = dependentFieldDetails[0];
      let commands = dependentFieldDetails[1];
      if (!fields) return null;

      fields = fields.split(',');
      fields.forEach((field, index) => {
        commands = commands.replace(new RegExp(`%${index + 1}`, 'g'), `context.${field}`)
      });

      commands = `<% context.result = (${commands}) %>`;
      let dependentPath = path.slice(0);
      dependentPath.pop();
      let context = dotProp.get(sign.attributes, dependentPath)
      const testCompiled = template(commands);
      
      testCompiled({ context });
      const result = context.result;
      delete context.result;

      if (!result) return null;
    }

    return h(Component, {
      sign,
      signConfig,
      attributeConfig,
      attributeValue,
      children: yieldChildren ? yieldChildren() : [],
      path,
      onChange
    })
  }
}
