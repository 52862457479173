var _ = require('lodash');
var moment = require('moment-timezone');

var exports = module.exports;
var defaultTimezone = 'America/New_York';

Date.prototype.getWeekOfMonth = function(exact) {
    var month = this.getMonth()
        , year = this.getFullYear()
        , firstWeekday = new Date(year, month, 1).getDay()
        , lastDateOfMonth = new Date(year, month + 1, 0).getDate()
        , offsetDate = this.getDate() + firstWeekday - 1
        , index = 1 // start index at 0 or 1, your choice
        , weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7)
        , week = index + Math.floor(offsetDate / 7)
    ;
    if (exact || week < 2 + index) return week;
    return week === weeksInMonth ? index + 5 : week;
};

exports.startOfWeekLocale = function(date, jurisdiction) {
  let timeZone = jurisdiction.attributes["TIMEZONE"] || defaultTimezone;
  return moment.tz(date, timeZone).startOf('isoWeek').format();
};

exports.weekOfMonthLocale = function(date, jurisdiction) {
  let timeZone = jurisdiction.attributes["TIMEZONE"] || defaultTimezone;
  let startOfWeek = moment.tz(date, timeZone).startOf('isoWeek').toDate();
  return startOfWeek.getWeekOfMonth(true);
}

exports.currentTimestampInLocale = function(jurisdiction) {
  let timeZone = jurisdiction.attributes["TIMEZONE"] || defaultTimezone;
  return moment.tz(new Date(), timeZone);
}