const t = require('tcomb')
const h = require('react-hyperscript')
const {propTypes} = require('tcomb-react')

const {ToggleButton} = require('../../../components/button')
const Mutator = require('../../../util/mutator')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')

module.exports = BooleanComponent

BooleanComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Base.Type,
  data: SignAttrValue.Boolean.Type
})

function BooleanComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  return h('div', rest, [
    h('div', {className: 'flex items-center justify-between'}, [
      h('div', [
        h(ToggleButton, {
          onClick: () => mutator.onSetValue(['value'], true),
          selected: data.value
        }, 'Yes'),
        h(ToggleButton, {
          onClick: () => mutator.onSetValue(['value'], false),
          selected: !data.value
        }, 'No')
      ])
    ])
  ])
}
