const h = require('react-hyperscript')

const renderDescription = require('../../../util/render-description')
const {SignAttrValue} = require('../../../schema')
const {IconButton} = require('../../../components/button')
const Mutator = require('../../../util/mutator')

module.exports = ArrayValue

function ArrayValue ({
  path,
  signConfig,
  attributeConfig,
  attributeValue,
  children,
  onChange
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  return h('div', {className: 'pa2'}, [
    !attributeConfig.description
      ? undefined
      : h('div', {className: 'pa2 b'}, renderDescription(attributeConfig.description)),

    children.map(mapChild),

    h(IconButton, {
      className: 'bg-blue white mt3',
      onClick: () => mutator.onPushValue(['values'], createNewArrayItem())
    }, '+'),
    h('span', {className: 'b mh2'}, 'Add New')
  ])

  function mapChild (child, index, array) {
    return h('div', {
      key: path.concat(['values', index]).join('.'),
      className: 'flex items-start mt2 w-100'
    }, [
      h('div', {className: 'tc mr2 mt2'}, [
        h(IconButton, {
          className: 'bg-red white',
          onClick: () => {
            if (!window.confirm('Are you sure you want to remove this value?')) return
            mutator.onRemoveValue(['values'], index)
          }
        }, 'x'),
        h('div', {className: 'b'}, `[${index + 1}/${array.length}]`)
      ]),

      child
    ])
  }

  function createNewArrayItem () {
    const structure = signConfig.structures[attributeConfig.structure]

    const fields = Object.keys(structure.fields).reduce(function (acc, fieldKey) {
      const fieldAttributeConfig = structure.fields[fieldKey]
      const FieldValueType = SignAttrValue[fieldAttributeConfig.type].Type

      acc[fieldKey] = FieldValueType({})

      return acc
    }, {})

    return SignAttrValue.Structure.Type({
      fields
    })
  }
}
