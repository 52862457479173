const h = require('react-hyperscript')
import Select from 'react-select';
const classes = require('classnames')
const sf = require('sheetify')
sf('./signs.css')

const { Button, IconButton } = require('../../components/button')
const ImageEditor = require('../../components/image-editor')

const SignsContainer = require('../../containers/signs-container')
const ReactTags = require('react-tag-autocomplete');

function renderSigns (props) {

  let selectableCopyTags = (props.copyTags || []).filter(tag => tag.category != undefined);

  return h('div', {className: 'w-50 ba b--black signs-container'
  }, [
    ...props.datapoint.signs.map(({priority, category, directionLeft, directionRight, copyTags}, index) => {

      const signNumber = index + 1;

      return h('div', {
        className: classes('pv2 flex items-center pointer ph3 b--black-20 bg-black-10', {
          'bg-black-10': props.signIndex === index
        }),
        onClick: () => props.paginate(index)
      }, [
        h(IconButton, {
          className: 'bg-red white mr2',
          onClick: () => {
            if (!window.confirm('Are you sure you want to remove Sign ' + signNumber + '?')) return
            if ((props.datapoint.signs[index].copyTags) && (props.datapoint.signs[index].copyTags.length == 1)) {
              props.copyTagsDelete(props.datapoint.signs[index].copyTags[0].name);
            }
            props.mutateCurrentDatapoint({
              path: ['signs'],
              type: 'remove',
              value: index
            })
          }
        }, 'x'),
        h('span', {
          className: index === props.signIndex ? 'b' : ''
        }, `Sign ${signNumber} (${category || 'No Category Selected'}) ${directionLeft ? '←' : ''}${directionRight ? '→' : ''} `),
        category ?
        h(ReactTags, {
          tags: copyTags,
          placeholder: "Copytag?",
          handleDelete: () => onTagDelete(index, props),
          handleAddition: (tag) => onTagAdd(tag, index, props),
          allowNew: (copyTags || []).length === 0 
        }) :
        selectableCopyTags.length > 0 ? h(Select, {
          className: 'w-30',
          placeholder: 'Select Copytag',
          clearable: false,
          options: selectableCopyTags.map((tag) => ({value: tag, label: tag.name})),
          onChange: (item) => {
            props.mutateCurrentDatapoint({
              path: ['signs', index, 'priority'],
              type: 'set',
              value: item.value.priority
            });
            props.mutateCurrentDatapoint({
              path: ['signs', index, 'attributes'],
              type: 'set',
              value: item.value.attributes
            });
            props.mutateCurrentDatapoint({
              path: ['signs', index, 'signConfig'],
              type: 'set',
              value: item.value.signConfig
            });
            props.mutateCurrentDatapoint({
              path: ['signs', index, 'category'],
              type: 'set',
              value: item.value.category
            });

            

//            mutator.onSetValue(['category'], item.value)
//            mutator.onSetValue(['attributes'], {}) // remove all set attributes when switching category
//            mutator.onSetValue(['signConfig'], props.signConfigsByCategory[item.value].id)
          }
        }) : null,
        h('div.flex-auto'),
        h('div.mr1 ', 'Priority:'),
        h('input', {
          className: 'w3',
          type: 'number',
          min: 0,
          value: priority,
          onClick: (ev) => ev.stopPropagation(),
          onChange: (ev) => props.mutateCurrentDatapoint({
            path: ['signs', index, 'priority'],
            type: 'set',
            value: Number(ev.target.value)
          })
        })
      ])
    }),
    h('div', {
      className: 'h3 pa3 bg-black-10 flex justify-center'
    }, [
      h(Button, {
        className: 'mh2 flex-justify-center',
        theme: 'secondary',
        onClick: () => {
          props.paginate(props.datapoint.signs.length)
          props.addNewSignToCurrentDatapoint()
        }
      }, 'Add New Sign')
    ]),

    props.signIndex > props.datapoint.signs.length - 1
      ? h('h2', {className: 'text-center'}, 'NO SIGN SELECTED')
      : h(SignsContainer, {
        signIndex: props.signIndex
      })
  ])
}

function onTagAdd(tag, index, props) {

  let tags = props.copyTags.map(tag => tag.name);
  if (tags.includes(tag.name)) {
    return;
  }

  props.mutateCurrentDatapoint({
    path: ['signs', index, 'copyTags'],
    type: 'set',
    value: (props.datapoint.signs[index].copyTags || []).concat(tag)
  });

  props.copyTagsAdd({name: tag.name, rawDataPoint: props.datapoint.rawSignId });
}

function onTagDelete(index, props) {
  props.copyTagsDelete(props.datapoint.signs[index].copyTags[0].name);

  props.mutateCurrentDatapoint({
    path: ['signs', index, 'copyTags'],
    type: 'set',
    value: []
  });

}

module.exports = renderSigns