const h = require('react-hyperscript')
const classes = require('classnames')

module.exports = DefinitionList

function DefinitionList ({
  className,
  items,
  ...rest
}) {
  return h('dl', {
    className: classes(className, 'lh-title mt0'),
    ...rest
  }, items.map(renderItem))
}

function renderItem (item, index) {
  return [
    h('dt', {
      key: item.label,
      className: classes('f6 b', {
        mt2: index > 0
      })
    }, item.label),
    h('dd', {
      key: item.value,
      className: 'm10'
    }, item.value)
  ]
}
