const t = require('tcomb')

const SUBTYPES = ['WEEKDAY_OF_WEEK', 'WEEKDAY_OF_MONTH', 'SPECIFIC_DAYS', 'ALL_DAYS']
const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const SPECIAL_INCLUDES = [{ title: 'School Day', value: 'SchoolDay'},
                          { title: 'Public Holiday', value: 'Holiday'}]
const SPECIAL_EXCLUDES = [{ title: 'School Day', value: 'Exclude-SchoolDay'},
                          { title: 'Public Holiday', value: 'Exclude-Holiday'}]
const DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

exports.SUBTYPES = SUBTYPES
exports.WEEKDAYS = WEEKDAYS
exports.DAYS = DAYS
exports.SPECIAL_INCLUDES = SPECIAL_INCLUDES
exports.SPECIAL_EXCLUDES = SPECIAL_EXCLUDES

exports.WeekDayOfMonthType = t.struct({
  weekDay: t.enums.of(WEEKDAYS),
  dayNumber: t.maybe(t.Number)
}, {
  name: 'WeekDayOfMonth',
  defaultProps: {
    weekDay: WEEKDAYS[0],
    dayNumber: null
  }
})

exports.Type = t.struct({
  type: t.enums.of('DayRange'),
  subtype: t.enums.of(SUBTYPES),
  weekDay: t.list(t.enums.of([...WEEKDAYS, ...['Holiday', 'School']], 'WeekDay')),
  weekDayOfMonth: t.list(exports.WeekDayOfMonthType),
  days: t.list(t.enums.of(DAYS, 'Day')),
  specialInclusions: t.maybe(t.String),
  specialExclusions: t.maybe(t.String)
}, {
  name: 'SignAttrValue.DayRange',
  defaultProps: {
    type: 'DayRange',
    subtype: SUBTYPES[0],
    weekDay: [],
    weekDayOfMonth: [],
    days: [],
    specialInclusions: '',
    specialExclusions: ''
  }
})

exports.hasValue = (data) => data.subtype === 'ALL_DAYS' ? true
  : data.subtype === 'WEEKDAY_OF_MONTH' ? data.weekDayOfMonth.length > 0
  : data.subtype === 'WEEKDAY_OF_WEEK' ? data.weekDay.length > 0
  : data.days.length > 0
