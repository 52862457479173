var _ = require('lodash');
var moment = require('moment');


var exports = module.exports;

exports.stringify = function(range) {

  if (!_.isObject(range)) return "";

  //console.log(range);

  switch(range.subType) {
    case 'MONTHS_OF_YEAR':
      var output = (range.months & 0x0001) ? "Jan " : "";
      output += (range.months & 0x0002) ? "Feb " : "";
      output += (range.months & 0x0004) ? "Mar " : "";
      output += (range.months & 0x0008) ? "Apr " : "";
      output += (range.months & 0x0010) ? "May " : "";
      output += (range.months & 0x0020) ? "Jun " : "";
      output += (range.months & 0x0040) ? "Jul " : "";
      output += (range.months & 0x0080) ? "Aug " : "";
      output += (range.months & 0x0100) ? "Sep " : "";
      output += (range.months & 0x0200) ? "Oct " : "";
      output += (range.months & 0x0400) ? "Nov " : "";
      output += (range.months & 0x0800) ? "Dec " : "";
      return output;

    default:
      return "";
  }
};
