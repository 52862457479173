const t = require('tcomb')
const Base = require('./base')

const UNITS = ['Hours', 'Minutes']

exports.UNITS = UNITS

exports.Type = Base.Type.extend({
  unit: t.enums.of(UNITS),
  minimum: t.union([t.String, t.Number]),
  maximum: t.union([t.String, t.Number]),
  stepSize: t.union([t.String, t.Number])
}, {
  name: 'SignAttrConfig.Number',
  defaultProps: {
    unit: 'Hours',
    stepSize: 1
  }
})

exports.validateAttributeValue = function validateNumberAttributeValue (attributeConfig, attributeValue) {
  var error = Base.validateAttributeValue(attributeConfig, attributeValue)
  if (error) return error

  if ((attributeValue.value) && (!attributeConfig.stepSize)) attributeConfig.stepSize = 1;
  
  if (attributeValue.value < attributeConfig.minimum) {
    return new Error('Value must be greater than ' + attributeConfig.minimum + '.')
  }

  if (attributeValue.value > attributeConfig.maximum) {
    return new Error('Value must be less than ' + attributeConfig.maximum + '.')
  }

  if (attributeValue.value % attributeConfig.stepSize !== 0) {
    return new Error('Value must be a multiple of ' + attributeConfig.stepSize + '.')
  }
}
