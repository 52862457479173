const { render }   = require('react-dom')
const { Provider } = require('react-redux')
const { Router, IndexRedirect, Redirect,
  Route, browserHistory }  = require('react-router')
const h         = require('react-hyperscript')
const Store     = require('./store')
const sf        = require('sheetify')
sf('./index.css', {global: true})

const App       = require('./containers/app')
const Interpret = require('./containers/interpret')
const Login     = require('./containers/login')

Object.freeze = function noopObjectFreeze () {}

module.exports = function init (root) {
  return render(h(Provider, {store: Store()}, [
    h(Router, { history: browserHistory }, [
      h(Route, { path: '/', component: App }, [
        h(IndexRedirect, { to: '/interpret' }),
        h(Route, { path: '/interpret', component: Interpret }),
        h(Route, { path: '/login', component: Login }),
        h(Redirect, { path: '*', to: '/' })
      ])
    ])
  ]), root)
}
