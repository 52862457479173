const t = require('tcomb')

const SUBTYPES = ['MONTHS_OF_YEAR', 'SPECIFIC_MONTHS_AND_YEAR', 'ALL_MONTHS']
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const YEARS = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, null];
exports.SUBTYPES = SUBTYPES
exports.MONTHS = MONTHS
exports.YEARS = YEARS

exports.Type = t.struct({
  type: t.enums.of('MonthRange'),
  subtype: t.enums.of(SUBTYPES),
  months: t.list(t.enums.of(MONTHS)),
  year: t.enums.of(YEARS)
}, {
  name: 'SignAttrValue.MonthRange',
  defaultProps: {
    type: 'MonthRange',
    subtype: SUBTYPES[2],
    months: [],
    year: null
  }
})

exports.hasValue = (data) => data.subtype === 'ALL_MONTHS' ? true 
  : data.subtype === 'MONTHS_OF_YEAR' ? data.months.length > 0
  : ((data.months.length > 0) && (data.year != null))
