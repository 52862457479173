const {createActions} = require('redux-actions')
const api = require('../api')
const generateSchedule = require('../util/zone-generation/job-runner');
const _ = require('lodash');

const {
  objectInSortedKey,
  getSignsWithPriorityKey,
  getSignsOrderedInPriority,
  hierarchicalCombine,
  getJurisdictionById,
  getSignConfigById
} = require('../util/zone-generation/utils');

const runJob = require('../util/zone-generation/job-runner');

const identity = (n) => n

module.exports = createActions({
  login: (email, password) => {
    return api.dataCollection.login(email, password)
  },

  logout: () => {
    return api.dataCollection.logout()
  },

  fetchApiKey: () => {
    return api.dataCollection.fetchApiKey()
  },

})