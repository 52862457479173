const {handleActions} = require('redux-actions')

const {
    login,
    logout,
    fetchApiKey
} = require('../actions')

const initialState = {apiKey: null, loaded: false}

module.exports = handleActions({
  [login]: (state, action) => ({apiKey: action.payload.apiKey, errors: action.payload.errors, loaded: true}),
  [logout]: (state, action) => ({apiKey: null, loaded: true}),
  [fetchApiKey]: (state, action) => ({apiKey: action.payload.apiKey, loaded: true})
}, initialState)
