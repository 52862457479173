const h = require('react-hyperscript')
const classes = require('classnames')

module.exports = IconButton

function IconButton ({className, children, ...props}) {
  return h('button', {
    className: classes(
      className,
      'f6 pointer no-underline br-pill ph2 pv1 dib'
    ),
    ...props
  }, children)
}
