module.exports = function renderDescription (description) {
  if (typeof description === 'string') return description

  const keys = Object.keys(description)
  for (var i = 0; i < keys.length; i++) {
    if (keys[i].indexOf('en') > -1) {
      return description[keys[i]]
    }
  }
  return description[keys[0]]
}
