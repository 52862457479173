var _ = require('lodash');
var moment = require('moment-timezone');

var dateUtil = require('./dateUtil');

var Specials = {
  "SCHOOL_HOLIDAYS": 91,
  "PUBLIC_HOLIDAYS": 92
}

var exports = module.exports;

exports.determinePeriods = (jurisdiction, baseDateString, interval, duration, periodRanges) => {
  let periods = [];

  let timeZone = jurisdiction.attributes["TIMEZONE"] || dateUtil.defaultTimezone;
//  console.log(`TIMEZONE = ${timeZone}`);

//  console.log(JSON.stringify(periodRanges));
  let baseDate = moment.tz(baseDateString, timeZone);
  let baseDateDST = baseDate.isDST();
  let baseMonth = baseDate.month();
  let baseYear = baseDate.year();

  let intervalMinutes = interval * duration;
  let intervalEndDate = moment(baseDate).add(intervalMinutes, 'minutes');
  let intervalEndDateDST = intervalEndDate.isDST();
  let intervalDSTOffset = 0;
  if ((baseDateDST) && (!intervalEndDateDST)) intervalDSTOffset = 60;
  if ((!baseDateDST) && (intervalEndDateDST)) intervalDSTOffset = -60;
  intervalEndDate.add(intervalDSTOffset, "minutes");

  if (!periodRanges) {
    return {
        duration: (intervalMinutes + intervalDSTOffset) / interval,
        dstOffset: intervalDSTOffset,
        periods: [{ start: 0, end: ((intervalMinutes + intervalDSTOffset) / interval), maximumDuration: null, startDSTOffset: 0, endDSTOffset: intervalDSTOffset / interval}]
      };
  }

  let intervalEndMonth = intervalEndDate.month();
  let intervalEndYear = intervalEndDate.year();

  let monthsToProcess = (((intervalEndYear * 12) + intervalEndMonth) - ((baseYear * 12) + baseMonth)) + 1;
//  console.log("MONTHS TO PROCESS", monthsToProcess);

  let combinedPeriodSchedules = [];
  for (let i = 0; i < monthsToProcess; i++) {
    let generationBaseDate = moment(baseDate).date(1).add(i, 'months');
    let periodSchedules = generateMonthPeriodSchedules(generationBaseDate.month(), generationBaseDate.year(), periodRanges, jurisdiction);
    combinedPeriodSchedules.push(periodSchedules);
  }

  let daysToProcess = intervalEndDate.diff(baseDate, 'days') + 1;
  let dstOffset = 0;

//  console.log("BASE DATE", baseDate);

  for (let j = 0; j < daysToProcess; j++) {
    let queryDate = moment(baseDate).add(j, 'days');
    let queryDay = queryDate.date();
    let queryMonth = queryDate.month();
    let queryYear = queryDate.year();
    let queryMonthFromBase = ((queryYear * 12) + queryMonth) - ((baseYear * 12) + baseMonth);
    let periodSchedule = combinedPeriodSchedules[queryMonthFromBase];

    let queryDateDST = queryDate.isDST();

    let DSTadjusted = false;
  //  console.log(`Processing ${queryDate}`)
    //console.log(j, periodSchedule)
    periodSchedule.map(schedule => {
      if ((schedule.months.includes(queryMonth)) &&
          (schedule.days.includes(queryDay))) {

          let currentDSTOffset = dstOffset;

          if ((schedule.times.start == 0) && (schedule.times.end == 59)) schedule.times.end = 1440;
          if (schedule.times.end == 1439) schedule.times.end = 1440;

  //        console.log(`${queryDate} ${schedule.times.start}, ${schedule.times.end}, ${dstOffset}`);
          let startDateAffected = false; let startDSTOffset = dstOffset;
          let endDateAffected = false; let endDSTOffset = dstOffset;

          let scheduleStartDate = moment(queryDate).hour(0).minute(0).second(0).milliseconds(0).add(schedule.times.start, 'minutes');
          if ((queryDateDST) && (!scheduleStartDate.isDST())) { startDateAffected = true; if (!DSTadjusted) { dstOffset += 60; DSTadjusted = true; startDSTOffset = dstOffset; } }
          if ((!queryDateDST) && (scheduleStartDate.isDST())) { startDateAffected = true; if (!DSTadjusted) { dstOffset += -60; DSTadjusted = true; startDSTOffset = dstOffset; } }
          let scheduleStartIntervalFromBase = scheduleStartDate.diff(baseDate, 'minutes');

          let scheduleEndDate = moment(scheduleStartDate).add(schedule.times.end - schedule.times.start, "minutes");
          if ((queryDateDST) && (!scheduleEndDate.isDST())) { endDateAffected = true; if (!DSTadjusted) { dstOffset += 60; DSTadjusted = true; } endDSTOffset = dstOffset; }
          if ((!queryDateDST) && (scheduleEndDate.isDST())) { endDateAffected = true; if (!DSTadjusted) { dstOffset += -60; DSTadjusted = true; } endDSTOffset = dstOffset; }
          let scheduleEndIntervalFromBase = scheduleEndDate.diff(baseDate, 'minutes');

          if ((currentDSTOffset == -60) && (startDSTOffset == 0)) scheduleStartIntervalFromBase += 60;
          if ((currentDSTOffset == -60) && (endDSTOffset == 0)) scheduleEndIntervalFromBase += 60;
          if ((currentDSTOffset == 60) && (startDSTOffset == 0)) scheduleStartIntervalFromBase -= 60;
          if ((currentDSTOffset == 60) && (endDSTOffset == 0)) scheduleEndIntervalFromBase -= 60;

          // outside of interval range so ignore
          if (((scheduleStartIntervalFromBase < 0) && (scheduleEndIntervalFromBase < 0)) ||
              ((scheduleStartIntervalFromBase > intervalMinutes) && (scheduleEndIntervalFromBase > intervalMinutes))) {
//                console.log("Detected outside of interval range so ignored");
              return;
          }

          if (scheduleStartIntervalFromBase < 0) scheduleStartIntervalFromBase = 0;
          if (scheduleEndIntervalFromBase >= (intervalMinutes + intervalDSTOffset)) scheduleEndIntervalFromBase = intervalMinutes + intervalDSTOffset;

  //        let checkStartDate = moment(baseDate).add((scheduleStartIntervalFromBase) + (startDateAffected ? (startDSTOffset) : 0), 'minutes');
  //        let checkEndDate = moment(baseDate).add((scheduleEndIntervalFromBase) + (endDateAffected ? (endDSTOffset) : 0), 'minutes');
          if (scheduleStartIntervalFromBase != (intervalMinutes + intervalDSTOffset)) {
            periods.push({ /*checkStartDate: checkStartDate.format(), checkEndDate: checkEndDate.format(), */start: (scheduleStartIntervalFromBase / interval) + (startDateAffected ? (startDSTOffset / interval) : 0), end: (scheduleEndIntervalFromBase / interval) + (endDateAffected ? (endDSTOffset / interval) : 0), maximumDuration: schedule.maximumDuration, tariff: schedule.tariff, startDSTOffset: startDSTOffset / interval, endDSTOffset: endDSTOffset / interval })
          }
      } else {
        let scheduleEndDate = moment(queryDate).add(1440, 'minutes');
        if ((queryDateDST) && (!scheduleEndDate.isDST())) dstOffset += 60;
        if ((!queryDateDST) && (scheduleEndDate.isDST())) dstOffset += -60;
      }

    })

  }

  periods = _.sortBy(periods, ['start']);


  return {
    duration: (intervalMinutes + intervalDSTOffset) / interval,
    dstOffset: intervalDSTOffset,
    periods
  };
}

function generateMonthPeriodSchedules(month, year, periodRanges, jurisdiction) {
//  console.log(`generateMonthPeriodSchedules ${month} ${year}`);
  var periods = [];

  let startOfMonth = moment().hour(0).minute(0).second(0).milliseconds(0).date(1).month(month).year(year);
  let isoStartOfMonthDay = startOfMonth.isoWeekday();

  let endOfMonth = moment(startOfMonth).endOf('month');
  let numberOfMonthDays = endOfMonth.date();

  let schoolHolidays = jurisdiction.attributes["SCHOOL_HOLIDAYS"];
  let publicHolidays = jurisdiction.attributes["PUBLIC_HOLIDAYS"];

  let handleSchoolHolidaysInclusions = false;
  let handleSchoolHolidaysExclusions = false;

  let handlePublicHolidaysInclusions = false;
  let handlePublicHolidaysExclusions = false;


  let periodsToProcess = periodRanges.values;
  periodsToProcess.map(period => {

    let applicableMonths = getApplicableMonths(period.fields["MONTH_RANGE"]);
    let applicableDays = getApplicableDays(period.fields["DAY_RANGE"], isoStartOfMonthDay, numberOfMonthDays);
    let applicableTimes = getApplicableTimes(period.fields["TIME_RANGE"]);
    let maximumDuration = null;
    if (period.fields["MAXIMUM_DURATION"]) {
      maximumDuration = period.fields["MAXIMUM_DURATION"].value;
    }

    let tariff = period.fields["TARRIF"] || period.fields["TARIFF"] || null;
    if (tariff && tariff.type) delete tariff.type;

    let include = applicableDays["include"];
    let exclude = applicableDays["exclude"];

//    console.log(`includeDays=${include} excludeDays=${exclude}`);

    if ((include) && (include.includes(Specials["SCHOOL_HOLIDAYS"]))) handleSchoolHolidaysInclusions = true;
    if ((include) && (include.includes(Specials["PUBLIC_HOLIDAYS"]))) handlePublicHolidaysInclusions = true;
    if ((exclude) && (exclude.includes(Specials["SCHOOL_HOLIDAYS"]))) handleSchoolHolidaysExclusions = true;
    if ((exclude) && (exclude.includes(Specials["PUBLIC_HOLIDAYS"]))) handlePublicHolidaysExclusions = true;

    periods.push({ months: applicableMonths, days: applicableDays, times: applicableTimes, maximumDuration: maximumDuration, tariff: tariff });
  });

  // Need to handle the arrays of periods correctly
  if ((handleSchoolHolidaysInclusions) || (handleSchoolHolidaysExclusions)) {
      if (schoolHolidays) {
        let applicableSchoolDays = getApplicableSchoolDays(month, year, schoolHolidays, numberOfMonthDays);
        periods.map(period => {
            let include = period.days["include"];
            let exclude = period.days["exclude"];

            if ((include) && (include.includes(Specials["SCHOOL_HOLIDAYS"]))) include = _.union(include, applicableSchoolDays);
            if ((exclude) && (exclude.includes(Specials["SCHOOL_HOLIDAYS"]))) include = _.pullAll(include, applicableSchoolDays);
        });
      }
  }

  if ((handlePublicHolidaysInclusions) || (handlePublicHolidaysExclusions)) {
    if (publicHolidays) {
      let applicablePublicHolidays = getApplicablePublicHolidays(month, year, publicHolidays);
      periods.map(period => {
        let include = period.days["include"];
        let exclude = period.days["exclude"];

        if ((include) && (include.includes(Specials["PUBLIC_HOLIDAYS"]))) include = _.union(include, applicablePublicHolidays);
        if ((exclude) && (exclude.includes(Specials["PUBLIC_HOLIDAYS"]))) include = _.pullAll(include, applicablePublicHolidays);
      });
    }
  }

  periods.map(period => {
    period.days = period.days.include;

    // remove special days fields from day array if present.
    let index = period.days.indexOf(Specials["SCHOOL_HOLIDAYS"]);
    if (index > -1) period.days.splice(index, 1);
    index = period.days.indexOf(Specials["PUBLIC_HOLIDAYS"]);
    if (index > -1) period.days.splice(index, 1);
  })

  // console.log(`
  //   ----------------- PERIODS -------------------------
  // `);
  // console.log(periods);

  return periods;
}

// generates an array of month days where school holidays apply.  Assumes school days
// are monday - friday and never weekends
function getApplicableSchoolDays(month, year, schoolHolidays, numberOfMonthDays) {
  var applicableDays = [];

  if (!_.isArray(schoolHolidays)) return [];

  let queryStart = moment(`01/${month+1}/${year}`, 'DD/MM/YYYY');
  let queryStartISOWeekDay = queryStart.isoWeekday();
  let queryEnd = moment(`${numberOfMonthDays}/${month+1}/${year}`, 'DD/MM/YYYY');

  schoolHolidays.map(holiday => {
    let start = moment(holiday.start, 'DD/MM/YYYY');
    let startYear = start.year();
    let end = moment(holiday.end, 'DD/MM/YYYY');

    let control = moment(`01/01/${startYear}`, 'DD/MM/YYYY');
    let startDaysFromControl = start.diff(control, 'days');
    let endDaysFromControl = end.diff(control, 'days');
    let holidayRange = createNumberArray(startDaysFromControl, endDaysFromControl);

    let queryStartDaysFromControl = queryStart.diff(control, 'days');
    let queryEndDaysFromControl = queryEnd.diff(control, 'days');
    let queryRange = createNumberArray(queryStartDaysFromControl, queryEndDaysFromControl, queryStartISOWeekDay, 5, 7);

    let relevantRange = _.intersection(holidayRange, queryRange);
    relevantRange.map(dayOffset => {
      applicableDays.push((dayOffset - queryStartDaysFromControl) + 1);
    })
  });

  return _.uniq(_.sortBy(applicableDays));
}

function createNumberArray(start, end, controlOffset = 0, controlLimit = 0, controlMax = 0) {
  var counter = controlOffset;

  var result = [];
  for (let i = start; i <= end; i++) {
    if (controlMax > 0) {
      if (counter <= controlLimit) result.push(i);
      counter++;
      if (counter > controlMax) counter = 1;
    } else {
      result.push(i)
    }
  }

  return result;
}

function getApplicablePublicHolidays(month, year, publicHolidays) {
  var applicableDays = [];

  if (!_.isArray(publicHolidays)) return [];
  publicHolidays.map(holiday => {
    let holidayDate = moment(holiday.date, 'DD/MM/YYYY');
    if ((holidayDate.month() == month) && (holidayDate.year() == year)) {
      applicableDays.push(holidayDate.date());
    }
  })

  return _.uniq(_.sortBy(applicableDays));
}

function getApplicableTimes(timeRange) {
  var times = {};

  if (timeRange == null) return {};

  switch(timeRange.subtype) {
    case 'STANDARD_RANGE':
      times["start"] = timeRange.startTime;
      times["end"] = timeRange.endTime;
      break;
    default:
      break;
  }

  return times;
}


function getApplicableDays(dayRange, isoWeekday, numberOfMonthDays) {

  var include = [];
  var exclude = [];

  let allDays = [];
  let weekDaysInMonth = {};
  let currentDay = isoWeekday - 1;
  for (let i = 0; i < numberOfMonthDays; i++) {
      allDays.push(i+1);
      if (weekDaysInMonth[currentDay] == undefined) weekDaysInMonth[currentDay] = [];
      weekDaysInMonth[currentDay].push(i+1);
      currentDay = (currentDay + 1) % 7;
  }

  if (dayRange == null) return allDays;

  switch (dayRange.subtype) {
    case 'ALL_DAYS':
      include = allDays;
      break;

    case 'WEEKDAY_OF_WEEK':
      dayRange.weekDay.map(day => {
        switch(day) {
          case 'Monday':
            include = include.concat(weekDaysInMonth[0]);
            break;
          case 'Tuesday':
            include = include.concat(weekDaysInMonth[1]);
            break;
          case 'Wednesday':
            include = include.concat(weekDaysInMonth[2]);
            break;
          case 'Thursday':
            include = include.concat(weekDaysInMonth[3]);
            break;
          case 'Friday':
            include = include.concat(weekDaysInMonth[4]);
            break;
          case 'Saturday':
            include = include.concat(weekDaysInMonth[5]);
            break;
          case 'Sunday':
            include = include.concat(weekDaysInMonth[6]);
            break;
          case 'SchoolDay':
            include.push(Specials["SCHOOL_HOLIDAYS"]);
            break;
          case 'Holiday':
            include.push(Specials["PUBLIC_HOLIDAYS"]);
            break;
          default:
            console.log("NOT HANDLED", day);
            break;
        }
      });
      break;

      case 'WEEKDAY_OF_MONTH':
        dayRange.weekDayOfMonth.map(day => {
          switch(day.weekDay) {
            case 'Monday':
              include.push(weekDaysInMonth[0][day.dayNumber - 1]);
              break;
            case 'Tuesday':
              include.push(weekDaysInMonth[1][day.dayNumber - 1]);
              break;
            case 'Wednesday':
              include.push(weekDaysInMonth[2][day.dayNumber - 1]);
              break;
            case 'Thursday':
              include.push(weekDaysInMonth[3][day.dayNumber - 1]);
              break;
            case 'Friday':
              include.push(weekDaysInMonth[4][day.dayNumber - 1]);
              break;
            case 'Saturday':
              include.push(weekDaysInMonth[5][day.dayNumber - 1]);
              break;
            case 'Sunday':
              include.push(weekDaysInMonth[6][day.dayNumber - 1]);
              break;
            default:
              break;
          }
        });
        break;
    default:
      break;
  }

  if (_.isArray(dayRange.specialInclusions)) {
    dayRange.specialInclusions.map(day => {
      switch (day) {
        case 'SchoolDay':
          include.push(Specials["SCHOOL_HOLIDAYS"]);
          break;
        case 'Holiday':
          include.push(Specials["PUBLIC_HOLIDAYS"]);
          break;
        default:
          break;
      }
    });
  }

  exclude = [];
  if (_.isArray(dayRange.specialExclusions)) {
    dayRange.specialExclusions.map(day => {
      switch (day) {
        case 'SchoolDay':
          exclude.push(Specials["SCHOOL_HOLIDAYS"]);
          break;
        case 'Holiday':
          exclude.push(Specials["PUBLIC_HOLIDAYS"]);
          break;
        default:
          break;
      }
    });
  }

  return { include: _.sortBy(_.uniq(include)), exclude: _.sortBy(_.uniq(exclude)) };
}

function getApplicableMonths(monthRange) {

  let allMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  if (monthRange == null) return allMonths;

  switch (monthRange.subtype) {
    case 'ALL_MONTHS':
      return allMonths;
    case 'MONTHS_OF_YEAR':
      var months = [];
      monthRange.months.map(month => {
        switch (month) {
          case 'January':
            months.push(0);
            break;
          case 'February':
            months.push(1);
            break;
          case 'March':
            months.push(2);
            break;
          case 'April':
            months.push(3);
            break;
          case 'May':
            months.push(4);
            break;
          case 'June':
            months.push(5);
            break;
          case 'July':
            months.push(6);
            break;
          case 'August':
            months.push(7);
            break;
          case 'September':
            months.push(8);
            break;
          case 'October':
            months.push(9);
            break;
          case 'November':
            months.push(10);
            break;
          case 'December':
            months.push(11);
          default:
            break;
        }
      });
      return _.sortBy(months);
    default:
      return [];
  }
}
