const fetch = require('unfetch')
const join = require('url-join')
const config = require('../config')
const uuidv4 = require("uuid/v4");

module.exports = {
  getAllJurisdictions,
  saveDatapoint,
  saveSign,
  deleteSign,
  getParentJurisdictionIds,
  getJurisdiction,
  getCategoriesByJurisdiction,
  getSignConfigsByJurisdiction,
  getSignConfigByJurisdictionAndCategory,
  fetchDatapointDetails,
  fetchSignConfig,
  saveZone
}
window.papi = module.exports

function fetchDatapointDetails (id) {
  if (!id) return Promise.resolve(null);
  return fetch(join(config.pam.base, 'datapoint', id), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
  .then(res => {
    if (res.ok) return res.json();
    console.log('datapoint', id, 'not found in PAM');
    return Promise.resolve(null);
  })
}

function fetchSignConfig (id) {
  if (!id) return Promise.resolve(null);
  return fetch(join(config.pam.base, 'signconfig', id), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
  .then(res => {
    if (res.ok) return res.json();
    console.log('datapoint', id, 'not found in PAM');
    return Promise.resolve(null);
  })
}

/**
 * Fetches all jurisdictions
 * @returns {*}
 */
function getAllJurisdictions () {
  return fetch(join(config.pam.base, 'jurisdiction?limit=10000'), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }).then(res => res.json())
}

/**
 * Fetches all parent jurisdiction ids associated with a jurisdiction
 * @param jurisdictionId
 * @param parentIds
 */
function getParentJurisdictionIds (jurisdictionId, parentIds = []) {
  return getJurisdiction(jurisdictionId).then((jurisdiction) => {
    parentIds.push(jurisdiction.id)
    if (!jurisdiction.parent) {
      return parentIds
    }

    parentIds.push(jurisdiction.parent.id)

    if (!jurisdiction.parent.parent) {
      return parentIds
    }

    return getParentJurisdictionIds(jurisdiction.parent.parent, parentIds)
  })
}

/**
 * Fetches a single jurisdiction
 * @param jurisdictionId
 */
function getJurisdiction (jurisdictionId) {
  return fetch(join(config.pam.base, 'jurisdiction', jurisdictionId), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }).then(res => res.json())
}

/**
 * Fetches sign configs by jurisdiction, including all the configs associated with each parent jurisdiction
 * @param jurisdictionId
 * @returns {Promise.<TResult>|Promise<T>}
 */
function getSignConfigsByJurisdiction (jurisdictionId) {
  let jurisdictionHierarchy;
  return getParentJurisdictionIds(jurisdictionId).then((jurisdictionIds) => {
    jurisdictionHierarchy = jurisdictionIds;
    const where = { jurisdiction: jurisdictionIds }
    const query = '?limit=10000&where=' + JSON.stringify(where);
    return fetch(join(config.pam.base, 'signconfig', query), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(res => res.json())
    .then(signConfigs => {
      return ({signConfigs, jurisdictionHierarchy });
    });
  })
}

/**
 * Fetches categories by jurisdiction
 * @param jurisdictionId
 */
function getCategoriesByJurisdiction (jurisdictionId) {
  return getSignConfigsByJurisdiction(jurisdictionId).then((jurisdictions) => {
    if (!Array.isArray(jurisdictions)) {
      return jurisdictions
    }
    return jurisdictions.map(jurisdiction => jurisdiction.category)
  })
}

/**
 * Fetches a sign config by jurisdiction and category.
 * @param jurisdictionId
 * @param category
 */
function getSignConfigByJurisdictionAndCategory (jurisdictionId, category) {
  return getParentJurisdictionIds(jurisdictionId).then((jurisdictionIds) => {
    const where = { category, jurisdiction: jurisdictionIds }
    return fetch(join(config.pam.base, 'signconfig?limit=1&where=' + JSON.stringify(where)), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }).then(res => res.json()).then((result) => {
      if (Array.isArray(result)) {
        return result[0]
      }
      return result
    })
  })
}

/**
 * Creates or updates a datapoint
 * @param datapoint
 * @returns {*}
 */
function saveDatapoint(datapoint) {
  if (datapoint.id && datapoint.id.split('-').length === 5) {
    let options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(datapoint)
    };

    return fetch(join(config.pam.base, 'datapoint', datapoint.id), {
      method: 'put',
      ...options
    })
    .then(res => res.json())
    .catch(err => {
      console.log('Error saving datapoint', err);
      return { message: 'Error saving datapoint' }
    })
  } else {
    datapoint.id = uuidv4();
    let options = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(datapoint)
    };
    return fetch(join(config.pam.base, 'datapoint'), {
      method: 'post',
      ...options
    })
    .then(res => res.json())
    .catch(err => {
      console.log('Error saving datapoint', err);
      return { message: 'Error saving datapoint' }
    })
  } 
}

/**
 * Creates or updates a sign
 * @param sign
 * @returns {*}
 */
function saveSign (sign) {
  let options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(sign)
  };

  if (sign.id)
    return fetch(join(config.pam.base, 'sign', sign.id), {
      method: 'put',
      ...options
    })
    .then(res => res.json())
    .catch(err => {
      console.log('Error saving sign', err);
      return { message: 'Error saving sign' }
    })

  else return fetch(join(config.pam.base, 'sign'), {
      method: 'post',
      ...options
    })
    .then(res => res.json())
    .catch(err => {
      console.log('Error saving sign', err);
      return { message: 'Error saving sign' }
    })
}

function deleteSign (signId) {
  if (!signId) return Promise.resolve();
  
  return fetch(join(config.pam.base, 'sign', signId), {
    method: 'delete',
    ...{}
  })
  .then(res => res.json())
  .catch(err => {
    console.log('Error deleting sign', err);
    return { message: 'Error deleting sign' }
  })
}

function saveZone(zone) {
  let options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(zone)
  };

  if (zone.id) {
    return fetch(join(config.pam.base, 'zone', zone.id), {
      method: 'put',
      ...options
    })
    .then(res => res.json())
    .catch(err => {
      console.log('Error saving zone', err);
      return { message: 'Error saving zone' }
    })
  }

  else {
    return fetch(join(config.pam.base, 'zone'), {
      method: 'post',
      ...options
    })
    .then(res => res.json())
    .catch(err => {
      console.log('Error saving zone', err);
      return { message: 'Error saving zone' }
    })
  }
}

// window.deleteAllZones = deleteAllZones;
// function deleteAllZones() {
//   if (!confirm('Detele all zones?')) return;
//   return fetch(join(config.pam.base, 'zone?limit=all'), {
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json'
//     }
//   }).then(res => res.json())
//   .then(zones => {
//     Promise.all(zones.map(zone => {
//       return fetch(join(config.pam.base, 'zone', zone.id), {
//         method: 'delete',
//         headers: {
//           'Content-Type': 'application/json',
//           Accept: 'application/json'
//         }
//       }).then(res => res.json())
//     }))
//     .then(res => {
//       console.log(res);
//       console.log('zones deleted');
//     })
//   })
// }
