const Mutator = require('../util/mutator')
const castArray = require('cast-array')
const signApi = require('../api/sign')
const shortid = require('shortid')

module.exports = {
  currentDatapoint
}

const { createSign } = require('../util/format-legacy-api');

function currentDatapoint(state = null, action) {
  switch (action.type) {
    case 'AREA_IS_LOADING':
      if (action.isLoading) return null;
      return state;

    case 'SELECT_DATAPOINT':
      return action.datapoint

    case 'setDatapointJurisdiction':
      return {
        ...state,
        jurisdiction: action.payload
      }

    case 'addNewSignToCurrentDatapoint':
      return Mutator.mutate(state, {
        path: ['signs'],
        type: 'push',
        value: {
          ...createSign(),
          imageUrl: ''
        }
      })

    case 'mutateCurrentDatapoint':
      return Mutator.mutate(state, action.payload)

    case 'updateDatapointLocation':
      return state;
      
    case 'saveCurrentDatapoint':
      if (action.error) {
        return {
          ...state,
          datapointErrors: castArray(action.payload)
        }
      }
      return state;

    case 'resetDatapoint':
      return state

    default:
      return state
  }
}

