const h = require('react-hyperscript')
const classes = require('classnames')
import Select from 'react-select'
const sf = require('sheetify')
sf('./side-menu.css')
const { Button } = require('../../components/button')

module.exports = {
  SideMenu,
}

function SideMenu({
  processingSide,
  setProcessingSide,
  saveProcessingSide,
  regeneratePathForward,
  regeneratePathBackward,
  isSavingProcess,
  isSavedProcess,
  showMenu,
  roadOffset,
  roadOffsetChange,
}) {
  let saveButton = isSavingProcess ? 'Saving...' : isSavedProcess ? 'Saved' : 'Save'

  
  return h('div', {className: classes('side-menu', {'hidden': !showMenu})}, [
    h('div', {className: 'processing-container'}, [
      h('label', {}, 'Processing Side'),
      h(Select, {
        className: 'processing-select',
        clearable: false,
        placeholder: 'Choose a processing side',
        value: processingSide,
        options: [
          { value: 'LEFT', label: 'Left' },
          { value: 'RIGHT', label: 'Right' }
        ],
        onChange: ({value}) => {
          setProcessingSide(value)
        }
      }) 
    ]),
    h(Button, {theme: 'secondary', className: 'save-button', onClick: saveProcessingSide}, saveButton),
    h(Button, {theme: 'primary', className: 'save-button', onClick: () => regeneratePathForward(roadOffset)}, 'Regenerate >>'),
    h(Button, {theme: 'warning', className: 'save-button', onClick: () => regeneratePathBackward(roadOffset)}, 'Regenerate <<'),
    h('span', {}, [
      h('div', { className: 'road-offset' }, 'Center Offset (m):'),
      h('input', {
        className: 'w3 road-offset-input',
        type: 'number',
        min: 0,
        value: roadOffset,
        onChange: roadOffsetChange
    })]),
    
  ])
}