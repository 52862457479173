const fs = require('fs')
const path = require('path')
const h = require('react-hyperscript')
const DataUri = require('create-data-uri')
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const blueDot = fs.readFileSync(path.join(__dirname, '../../assets/bluedot.svg'), 'base64')
const redPin = fs.readFileSync(path.join(__dirname, '../../assets/redpin.svg'), 'base64')

function loadingElement() {
    return (
        h('div', {
            style: { height: `100%` }
        })
    );
}

function containerElement() {
    return (
        h('div', {
            style: { height: `100%`, width: `100%`}
        })
    )
}

function mapElement() {
    return (
        h('div', {
            style: { height: `100%`}
        })
    )
}

function markerDragStart(e, id) {
  if (!e) return;
}

function markerDragEnd(e, currentDatapoint, updateDatapointLocation, mutateCurrentDatapoint) {

  if (!e) return;
  updateDatapointLocation(currentDatapoint.rawSignId, e.latLng.lat(), e.latLng.lng());
  mutateCurrentDatapoint({
    path: ['latitude'],
    type: 'set',
    value: e.latLng.lat()
  });
  mutateCurrentDatapoint({
    path: ['longitude'],
    type: 'set',
    value: e.latLng.lng()
  });
}


const MapAreaCompose = (props) => {
    const composedObject = compose(
        withProps({
          googleMapURL: "https://maps.googleapis.com/maps/api/js?libraries=drawing&key=AIzaSyAdvFAXMFjrdMXJlOwRaoDeXNKe8F96tTU",
          loadingElement: loadingElement(),
          containerElement: containerElement(),
          mapElement: mapElement(),
          location: props.location,
          points: props.points,
          markerDragStart: markerDragStart,
          markerDragEnd: markerDragEnd,
          currentDatapoint: props.currentDatapoint,
          updateDatapointLocation: props.updateDatapointLocation,
          mutateCurrentDatapoint: props.mutateCurrentDatapoint
        }),
        withScriptjs,
        withGoogleMap
      )((props) => {
          return (  
            h(GoogleMap, {defaultZoom: 18, defaultCenter: props.location}, 
                props.points.map(point => {
                    return h(Marker, 
                      { position: point, 
                        draggable: ((props.location.lat === point.lat) && (props.location.lng === point.lng)) ? true : false, 
                        icon: ((props.location.lat === point.lat) && (props.location.lng === point.lng)) ? 
                                { url: DataUri('image/svg+xml', redPin), size: new google.maps.Size(15, 25), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(7, 20)} : 
                                { url: DataUri('image/svg+xml', blueDot), size: new google.maps.Size(11, 11), origin: new google.maps.Point(0, 0), anchor: new google.maps.Point(5 , 5)},
                        onDragStart: (e) => markerDragStart(e, props.selectedId),
                        onDragEnd: (e) => markerDragEnd(e, props.currentDatapoint, props.updateDatapointLocation, props.mutateCurrentDatapoint),
                      }
                    )
                })            
            ));        
      }
      )

      return composedObject();
}

function MapArea(props) {
    return MapAreaCompose(props)
}

module.exports = MapArea;