const fs = require('fs')
const path = require('path')
const h = require('react-hyperscript')
const DataUri = require('create-data-uri')
const classes = require('classnames')
const {Link} = require('react-router')
const sf = require('sheetify')

const logo = fs.readFileSync(path.join(__dirname, '../../assets/logo.png'), 'base64')
const className = sf('./nav.css')

module.exports = function Nav ({ className, onLogoutClick, loggedIn, ...props }) {

  return h('header', {...props, className: classes(className, 'top-nav-container')}, [
    h('img', {
      className: 'logo',
      src: DataUri('image/png', logo)
    }),
    h('h1', {className: 'title'}, 'SPOT DIT'),
    loggedIn ? h('a', {className: 'nav-link active', href:'/interpret'}, 'Interpretation Tool') : null,
    h('div', {className: 'login-container nav-link'}, [
      loggedIn ? h(Link, {className: 'nav-link', to: '#', onClick: onLogoutClick}, 'Log out') : h(Link, {className: 'nav-link', to: '/login'}, 'Log in')
    ])
  ])
}
