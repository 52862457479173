const t = require('tcomb')
const Base = require('./base')

exports.Type = Base.Type.extend({
  minimumLength: t.union([t.String, t.Number]),
  maximumLength: t.union([t.String, t.Number])
}, 'SignAttrConfig.HTMLString')

exports.validateAttributeValue = function validateStringAttributeValue (attributeConfig, attributeValue) {
  var error = Base.validateAttributeValue(attributeConfig, attributeValue)
  if (error) return error

  if (String(attributeValue.value).length < attributeConfig.minimumLength) {
    return new Error('Value must be at least ' + attributeConfig.minimumLength + ' characters long.')
  }

  if (String(attributeValue.value).length > attributeConfig.maximumLength) {
    return new Error('Value must be less than ' + attributeConfig.maximumLength + ' characters long.')
  }
}
