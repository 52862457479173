const t = require('tcomb')
const h = require('react-hyperscript')
const arrayToHash = require('array-to-hash')
const {propTypes} = require('tcomb-react')

const {ToggleButton} = require('../../../components/button')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')
const Mutator = require('../../../util/mutator')
const SubtypeSelect = require('./_subtype-select')

module.exports = SelectorComponent

SelectorComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Selector.Type,
  data: SignAttrValue.Selector.Type
})

function SelectorComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  if (data.value === '---') {
    for (let i = 0; i < config.values.length; i++) {
      if (config.values[i].default) {
        data.value = config.values[i].value;
        mutator.onSetValue(['value'], data.value)
        break;
      }
    }  
  }

  return h('div', rest, [
    h(SubtypeSelect, {
      label: 'Select Option',
      value: data.value,
      options: config.values.map(e => {
        return { label: e.label ? e.label : e.value, value: e.value }
      }),
      onChange: (value) => {
        mutator.onSetValue(['value'], value)
      }
    })
  ])
}
