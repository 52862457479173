module.exports = {
  setCurrentZone
};

function setCurrentZone(zone) {
  if ((zone) && (zone.error)) {
    return {
      type: 'SELECT_ERROR_ZONE',
      zone
    }
  }
  
  return {
    type: 'SELECT_CURRENT_ZONE',
    zone
  }
}