const React = require('react')
const classes = require('classnames')
const h = require('react-hyperscript')
const { connect } = require('react-redux')
const { getPending, getError } = require('redux-submission')
const createDispatchers = require('../util/create-dispatchers')
const sf = require('sheetify')

const Area = require('./area/area')

const DataPoint = require('./datapoint/datapoint');

module.exports = connect(
  state => {
    return {
      auth: state.auth
    }
  }
)(React.createClass({
  render,
  componentWillMount
}))


function componentWillMount () {
  const {auth, router} = this.props
  if (!auth.apiKey) {
    router.push('/login')
    return
  }
}

function render () {
  const {props, state} = this

  let startArea = undefined;
  if ((props.location.query) && (props.location.query.as)) {
    startArea = props.location.query.as;
  }

  return h('div', {}, [
    h(Area, {startArea}),
    h(DataPoint)
  ])
}
