const React = require('react')
const h = require('react-hyperscript')
const {connect} = require('react-redux')
const {getPending, getError} = require('redux-submission')
const createDispatchers = require('../../util/create-dispatchers')
const sf = require('sheetify')

sf('./datapoint.css');

const ImageEditor = require('../../components/image-editor')
const {Button, IconButton} = require('../../components/button')
const {DangerAlert, SuccessAlert} = require('../../components/alert')

const Header = require('./header');
const DatapointDetails = require('./datapoint-details');
const Signs = require('./signs');

const {
  fetchAllJurisdictions,
  fetchSignConfig,
  fetchCategories,
} = require('../../actions');

const {
  addNewSignToCurrentDatapoint,
  mutateCurrentDatapoint,
  setDatapointJurisdiction,
  saveDatapoint,
  insertDatapoint,
  resetDatapoint,
  deleteDatapoint,
//  uploadSignImage,
  toggleMapOpen
} = require('../../actions/datapoint');

const {
  copyTagsAdd,
  copyTagsDelete 
} = require('../../actions/area');

function generateCopyTagsWithAttributes(area) {
  let { copyTags, formattedDps } = area;
  
  if (!copyTags) return [];

  return copyTags.map(tag => {
    let targetDatapoint = formattedDps.find(datapoint => datapoint.rawSignId == tag.rawDataPoint);
    if (targetDatapoint == undefined) return tag;

    let targetSign = targetDatapoint.signs.find(sign => (sign.copyTags) && (sign.copyTags.length == 1) && (sign.copyTags[0].name == tag.name));
    if (targetSign == undefined) return tag;

    return ({
      category: targetSign.category, 
      attributes: targetSign.attributes, 
      priority: targetSign.priority, 
      signConfig: targetSign.signConfig,
      ...tag});
  });
}

module.exports = connect(
  (state) => {
    let address = "";
    if ((state.area) && (state.area.route) && (state.area.route.shortName) && 
        (state.area.locality) && (state.area.locality.shortName)) {
          address = `${state.area.route.shortName}, ${state.area.locality.shortName}`;
    }

    let points = [];
    if (state.area.dataPoints) {
      state.area.dataPoints.map(datapoint => {
        points.push({ lat: datapoint.locations.captured.latitude, lng: datapoint.locations.captured.longitude });
      });
    }

    let providedCopyTags = generateCopyTagsWithAttributes(state.area);

    return {
      auth: state.auth,
      datapoint: state.currentDatapoint,
      jurisdictionById: state.config.jurisdictions,
      areaJurisdiction: state.config.areaJurisdiction,
      signConfigsById: state.config.signConfigsById,
      saveStatus: state.interpret.saveStatus,
      areaStart: state.area.start,
      areaEnd: state.area.end,
      address: address,
      points: points,
      mapOpen: state.mapOpen,
      tags: state.area.tags,
      copyTags: providedCopyTags,
      errors: [
        ...state.interpret.datapointErrors,
        getError(fetchAllJurisdictions, state),
        getError(fetchCategories, state),
        getError(fetchSignConfig, state),
//        getError(uploadSignImage, state)
      ]
        .filter(Boolean)
    };
  },
  (dispatch) => ({
    ...createDispatchers([
      addNewSignToCurrentDatapoint,
      fetchCategories,
      mutateCurrentDatapoint,
      setDatapointJurisdiction,
      resetDatapoint,
      deleteDatapoint,
      toggleMapOpen,
      insertDatapoint,
    ])(dispatch),
    saveDatapoint: (datapoint, signConfigsById, tags) => {
      const action = saveDatapoint(datapoint, signConfigsById, tags)
      dispatch(action)
      return action.payload
    },
    copyTagsAdd: (tag) => dispatch(copyTagsAdd(tag)),
    copyTagsDelete: (tag) => dispatch(copyTagsDelete(tag)),
  })
)(React.createClass({
  getInitialState,
  componentWillReceiveProps,
  render
}))

function getInitialState () {
  return {
    signIndex: 0,
    interpretedSuccessfully: false,
    mapOpen: false
  }
}

function componentWillReceiveProps (nextProps = {}) {
  const currentDatapoint = this.props.datapoint;
  const nextDatapoint = nextProps.datapoint;

  if (!nextProps.areaJurisdiction) return;
  if (!nextDatapoint) return;

  if (nextDatapoint && !nextDatapoint.jurisdiction) {
    this.props.setDatapointJurisdiction(nextProps.areaJurisdiction);
  }

  if (!currentDatapoint || (currentDatapoint.rawSignId !== nextDatapoint.rawSignId)) {
    this.setState(getInitialState());
  }
}

function render () {
  const {props, state} = this

  if (!props.datapoint) return null;

  const paginate = (index) => {
    this.setState({
      signIndex: index
    })
  }

  const onSaveDatapoint = (options) => {
    this.setState({ interpretedSuccessfully: false })

    const promise = props.saveDatapoint(props.datapoint, props.signConfigsById, props.tags).then(() => {
      this.setState({ interpretedSuccessfully: true })
    })
  }

  const onAddDatapoint = (options) => {
    if (confirm(`Are you sure you want to insert a new datapoint after ${options.rawSignId}?`)) {
      props.insertDatapoint(options.rawSignId);
    }     
  }

  const onDeleteDatapoint = (options) => {
    if (confirm(`Are you sure you want to delete datapoint ${options.rawSignId}?\r\n\r\nNOTE: This action cannot be undone!`)) {
      props.deleteDatapoint(options.rawSignId);
    } 
  }


  const onToggleMapOpen = () => {
    this.setState( {mapOpen: !this.state.mapOpen});
  }


  const messages = h('div', {className: 'messages-container'}, [
    props.errors.map((error) => {
      return h(DangerAlert, {key: error.message}, error.message)
    }),
    state.interpretedSuccessfully ? h(SuccessAlert, {}, 'Data point interpreted successfully') : null
  ])

  return h('div', {}, [
    h(Header, {...props, onSaveDatapoint, onDeleteDatapoint, onAddDatapoint}),
    messages,

    h('div', {className: 'flex flex-auto'}, [
      h(DatapointDetails, {...props, onToggleMapOpen, mapOpen: state.mapOpen }),
      h(Signs, {...props, signIndex: state.signIndex, paginate})
    ])
  ])
}
