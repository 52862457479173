const t = require('tcomb')

const SUBTYPES = ['PRO_RATED', 'PRO_RATED_WITH_DAILY_CAP', 'PRO_RATED_WITH_PERIOD_CAP', 'FIXED', 'FREE', 'STEPPED_BY_DURATION', 'SPECIAL_TARIFF']
const CURRENCIES = ['AUD', 'USD', 'GBP', 'EUR', 'MYR']

exports.SUBTYPES = SUBTYPES
exports.CURRENCIES = CURRENCIES

exports.Type = t.struct({
  type: t.enums.of('TariffType'),
  subtype: t.enums.of(SUBTYPES),
  minimumChargeUnit: t.Number, // The smallest unit of charge time, in minutes
  chargeInterval: t.Number, // The charge interval, in hours
  displayCharge: t.Number, // The charge amount per {chargeInterval} in {currency}
  displayChargeUnitSize: t.Number, //
  currency: t.enums.of(CURRENCIES),
  cappedCharge: t.Number,
}, {
  name: 'SignAttrValue.TariffType',
  defaultProps: {
    type: 'TariffType',
    subtype: SUBTYPES[0],
    minimumChargeUnit: 1,
    chargeInterval: 1,
    displayCharge: 1,
    displayChargeUnitSize: 60,
    currency: CURRENCIES[0],
    cappedCharge: 0,
  }
})

exports.hasValue = (data) => true // what do we do here?
