const t = require('tcomb')
const LocaleString = require('../locale-string')
const SignAttrValue = require('../sign-attr-value')

const TYPES = [
  'DayRange', 'MonthRange', 'TimeRange', 'TariffType', 'DateTime',
  'String', 'Number', 'Boolean', 'Array', 'Structure', 'Selector', 'HTMLString'
]

exports.TYPES = TYPES

exports.Type = t.struct({
  description: t.maybe(LocaleString.Type),
  type: t.enums.of(TYPES),
  structure: t.maybe(t.String),
  required: t.maybe(t.Boolean),
  order: t.maybe(t.union([t.String, t.Number])),
  showDependency: t.maybe(t.String)
}, 'SignAttrConfig.Base')

exports.validateAttributeValue = function validateBaseSignAttrValue (attributeConfig, attributeValue) {
  const hasValue = SignAttrValue[attributeConfig.type].hasValue(attributeValue)

  // SignAttrConfig of type "Base" only checks if "required" is fulfilled
  if (attributeConfig.required && !hasValue) {
    return new Error('Value is required.')
  }
}
