const React = require('react')
const {connect} = require('react-redux')
const h = require('react-hyperscript')
const classes = require('classnames')
const ls = require('local-storage')
import {ModalContainer, ModalDialog} from 'react-modal-dialog';

const StatusBar = require('../../components/area-status-bar')
const Schedule = require('../../containers/schedule/schedule');
const { LinePlot } = require('./line-plot');
const { SideMenu } = require('./side-menu');
const ZoneErrorView = require('../../components/zone-error-view');

const sf = require('sheetify')
sf('./area.css');

const {calculateZones } = require('../../actions/zones')
const {setCurrentZone } = require('../../actions/schedule');
const {fetchAllJurisdictions } = require('../../actions/config');
const {
  fetchNextArea,
  fetchArea,
  selectDatapoint,
  saveArea,
  regenerateArea,
  skipArea,
  setProcessingSide,
  saveProcessingSide,
  regeneratePaths,
  tagsChange,
} = require('../../actions/area');

const Area = React.createClass({
  getInitialState,
  componentWillMount,
  skipArea,
  calculateZones,
  saveArea,
  regenerateArea,
  render
})

function getInitialState() {
  return {
    collapsed: false,
    showMenu: false,
    roadOffset: 3,
  }
}

function componentWillMount(nextProps) {
  init.call(this);

  function init() {
    this.props.fetchAllJurisdictions().then(() => {
      if (ls.get('apiKey')) {
        if (this.props.startArea) {
          this.props.fetchArea(Number(this.props.startArea));
        } else {
          this.props.fetchNextArea();
        }  
      }
    });
  }
}

function toggleCollapse() {
  this.setState({
    collapsed: !this.state.collapsed,
    showMenu: false
  });

}

function toggleMenu() {
  this.setState({
    showMenu: !this.state.showMenu
  });
}

function onTagAdd(tag, previousTags, tapsChange) {
  let tags = [].concat(previousTags || [], tag.name);

  tapsChange(tags);
}

function onTagDelete(i, previousTags, tapsChange) {  
  let tags = [];
  if (previousTags.length > 1) {
    tags = previousTags.splice(i, 1);
  }

  tapsChange(tags);
}

function render () {

  const { props } = this;
  
  if (props.isLoading) return h('div', {}, 'Loading area...');

  if (!props.area) return null;
  if (!props.currentDatapoint) return null;
  
  let completion = Math.round(props.area.formattedDps.filter(dp => dp.id).length / props.area.formattedDps.length * 100);
  let interpretable = props.area.formattedDps.reduce((acc, dp) => {
    if (dp.rawSignId === props.area.start.id || dp.rawSignId === props.area.end.id) return acc;
    if (dp.status !== 'interpreted') return false;
    return acc;
  }, true);

  let self = this;

  return h('div', {className: 'area-container'}, [
    h(StatusBar, {
      area: props.area,
      loadArea: props.fetchArea,
      completion,
      canSave: props.zones.length,
      unsaved: props.unsaved,
      calculateZones: () => {
        props.calculateZones(props.area);
      },
      saveArea: props.saveArea,
//      saveArea: props.regenerateArea,
      skipArea: props.skipArea,
      interpretable,
      isSaving: props.areaSavePending,
      isCalcZones: props.calcZonesPending,
      onTagAdd: (tag) => onTagAdd(tag, props.area.tags, props.tagsChange),
      onTagDelete: (i) => onTagDelete(i, props.area.tags, props.tagsChange),
    }),
    props.currentZone ? h(Schedule, {zone: props.currentZone, close: () => {
      props.setCurrentZone(null);
    }}) : null,
    h('div', {
      className: classes('collapse-button no-select', {collapsed: this.state.collapsed} ),
      onClick: toggleCollapse.bind(this)
    }, this.state.collapsed ? '+' : '-'),
    h('div', {className: classes('menu-toggle no-select', {'hidden': this.state.collapsed}), onClick: toggleMenu.bind(this)}, '☰'),
    h(SideMenu, {
      processingSide: props.area.processingSide,
      setProcessingSide: props.setProcessingSide,
      saveProcessingSide: () => {
        props.saveProcessingSide(props.area.processingSide);
      },
      regeneratePathForward: () => {
        props.regeneratePaths(props.area.areaReference, props.area.processingSide, true, this.state.roadOffset)
      },
      regeneratePathBackward: () => {
        props.regeneratePaths(props.area.areaReference, props.area.processingSide, false, this.state.roadOffset) 
      },
      isSavingProcess: props.isSavingProcess,
      isSavedProcess: props.isSavedProcess,
      showMenu: this.state.showMenu,
      roadOffset: this.state.roadOffset,
      roadOffsetChange: (ev) => this.setState({roadOffset: ev.target.value})
    }),
    h('div', {className: classes('area-plot', {collapsed: this.state.collapsed})}, [
      h(LinePlot, {
        start: props.area.start,
        datapoints: props.area.formattedDps,
        end: props.area.end,
        selectDatapoint: props.selectDatapoint,
        processingSide: props.area.processingSide,
        currentDatapoint: props.currentDatapoint,
        zones: props.zones,
        collapsed: this.state.collapsed,
        onShowZone: props.setCurrentZone,
        unsaved: props.unsaved,
        selectedZone: props.selectedZone,
      })
    ]), 
    props.errorZone && h(ModalContainer, {
      onClose: () => props.setCurrentZone(null)
    }, 
    h(ModalDialog, {
      style: { height: '82%', width: '75%'},
      onClose: () => props.setCurrentZone(null)
    },
    h(ZoneErrorView, { errors: props.errorZone.errorDetail})))
  ])
}


function mapStateToProps(state, props) {
  return {
    area: state.area,
    isLoading: state.areaIsLoading,
    currentDatapoint: state.currentDatapoint,
    zones: state.zones,
    unsaved: state.interpret.unsaved,
    currentZone: state.schedule.currentZone,
    areaSavePending: state.interpret.areaSavePending,
    isSavingProcess: state.interpret.saveProcessingSidePending,
    isSavedProcess: state.interpret.saveProcessingSideSuccess,
    calcZonesPending: state.interpret.calcZonesPending,
    selectedZone: state.schedule.currentZone,
    errorZone: state.schedule.errorZone
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    fetchNextArea: () => dispatch(fetchNextArea()),
    fetchArea: areaId => dispatch(fetchArea(areaId)),
    selectDatapoint: dp => dispatch(selectDatapoint(dp)),
    fetchAllJurisdictions: () => dispatch(fetchAllJurisdictions()),
//    saveArea: () => dispatch(saveArea()),
    saveArea: () => dispatch(regenerateArea()),
    skipArea: area => dispatch(skipArea(area)),
    setCurrentZone: zone => dispatch(setCurrentZone(zone)),
    setProcessingSide: direction => dispatch(setProcessingSide(direction)),
    saveProcessingSide: direction => dispatch(saveProcessingSide(direction)),
    calculateZones: area => dispatch(calculateZones(area)),
    regeneratePaths: (areaId, processingSide, direction, offset) => dispatch(regeneratePaths(areaId, processingSide, direction, offset  )),
    tagsChange: tags => dispatch(tagsChange(tags))
  })
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(Area)
