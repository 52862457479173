const api = require('../api')

module.exports = {
  calculateZones
}

function calculateZones(area) {
  return (dispatch, getStore) => {
    dispatch(calcZonesPending(true));
    const { formattedDps: datapoints, processingSide } = getStore().area;
    api.dataCollection.getZones(area.areaReference)
      .then(({zones, errors}) => {
        // ensure unique datapoints
        zones.forEach(zone => {
          zone.datapoints = [...new Set(zone.datapoints)];
        });

        zones = zones.map(zone => ({
          ...zone,
          datapoints: zone.datapoints.map(datapoint => datapoints.find(dp => dp.id === datapoint).rawSignId)
        }));

        zones = insertErrorZones(zones, datapoints, processingSide, errors);
        dispatch({
          type: 'SET_ZONES',
          zones
        })
        dispatch(calcZonesPending(false));
      })
      .catch(error => {
        console.log('Zone calculation error', error);
        alert('Could not generate zones');
        dispatch(calcZonesPending(false));
      })
  }
}

function calcZonesPending(bool) {
  return {
    type: 'CALCULATE_ZONES_PENDING',
    bool
  };
}


function insertErrorZones(zones, datapoints, processingSide, errors) {
  let newZones = [];
  let dps = datapoints.map(dp => dp.rawSignId);

  if (processingSide === 'RIGHT') {
    dps.reverse();
  }

  for (let i = 0; i < zones.length; i++) {
    let zone = zones[i];
    let zoneStart = zone.datapoints[0];
    let zoneEnd = zone.datapoints[zone.datapoints.length - 1];
    let nextZone = zones[i + 1];
    let nextZoneStart = nextZone ? nextZone.datapoints[0] : dps[dps.length - 1];
    
    if (i === 0) {   
      if (zoneStart !== dps[0]) {
        let errorDetail = errors.find(e => e.id === zoneStart);
        if (errorDetail !== undefined) {
          newZones.push({
            datapoints: [dps[0], zoneStart],
            error: true,
            errorDetail: errorDetail.diff
          });
        }
      }
    }

    newZones.push(zone);
    
    if (zoneEnd !== nextZoneStart) {
      let errorDetail = errors.find(e => e.id === nextZoneStart);  
      if (errorDetail !== undefined) {
        newZones.push({
          datapoints: [zoneEnd, nextZoneStart],
          error: true,
          errorDetail: errorDetail ? errorDetail.diff : null
        });  
      }    
    }
  }

  return newZones;
}
