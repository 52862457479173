module.exports = {
  api: {
    base: "https://titan-stage.spotparking.com.au/" || 'https://titan-stage.spotparking.com.au',
    path: '/api/v1'
  },
  dataCollection: {
//    base: process.env.DATACOLLECTION_API || 'http://localhost:8000'
    base: 'https://data-collection-api.spotparking.com.au',
//      base: 'https://data-collection-api-demo-usa.spotparking.com.au'
  },
  pam: {
//    base: process.env.PAM_API || 'http://localhost:1337'
    base: "https://spot-pam-api-production.spotparking.com.au" || 'https://spot-pam-api-production.spotparking.com.au'
//      base: 'https://spot-pam-api-demo-usa.spotparking.com.au'
  }
}
