module.exports = {
  zones
}

function zones(state = [], action) {
  switch (action.type) {
    case 'SET_ZONES':
      return action.zones

    case 'SUBMIT_saveDatapoint':
      return []

    case 'addNewSignToCurrentDatapoint':
      return []

    case 'mutateCurrentDatapoint':
      return []

    case 'FETCH_AREA_SUCCESS':
      return []

    default:
      return state
  }
}