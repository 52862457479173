const t = require('tcomb')

const SUBTYPES = ['STANDARD_RANGE']

exports.SUBTYPES = SUBTYPES

exports.Type = t.struct({
  type: t.enums.of('TimeRange'),
  subtype: t.enums.of(SUBTYPES),
  startTime: t.Number,
  endTime: t.Number
}, {
  name: 'SignAttrValue.TimeRange',
  defaultProps: {
    type: 'TimeRange',
    subtype: SUBTYPES[0],
    startTime: 8 * 60, // 8am by default
    endTime: 17 * 60 // 5pm by default
  }
})

exports.validate = function (value) {
  if (value.startTime > value.endTime) {
    return new Error('Start time must be less than end time.')
  }
}

exports.hasValue = (data) => data.endTime - data.startTime > 0
