const h = require('react-hyperscript')
const { Button } = require('../button')
const sf = require('sheetify')
sf('./styles.css');

const ReactTags = require('react-tag-autocomplete');
function StatusBar({
  area,
  loadArea,
  completion,
  canSave,
  unsaved,
  calculateZones,
  saveArea,
  skipArea,
  interpretable,
  isSaving,
  isCalcZones,
  onTagAdd,
  onTagDelete,
}) {

  let tags =[];

  if (area.tags) {
    tags = area.tags.map((tag, i) => {
      return { id: i, name: tag };
    });
  }

  return h('div', {className: 'status-bar'}, [
    h('div', {className: 'grey'}, [
      h('span', 'Editing '),
      h('span', {
        className: 'white',
        onClick: () => {
          let areaId = prompt('Enter ID of new area');
          if (areaId) loadArea(areaId);
        }
      }, 'Area ' + area.start.id),
      h('span', {className: 'area-status'}, 'Status: ' + area.status),
    ]),
    h(ReactTags, {
      tags: tags,
      handleDelete: onTagDelete,
      handleAddition: onTagAdd,
      allowNew: true
    }),
    /*h(TagsInput, {
      className: 'react-tagsinput area-tag',
      value: area.tags || [],
      onlyUnique: true,
      onChange: onTagsChange
    }),*/
    h('div', {className: 'right-align'}, [

      h('div', {className: 'completion'}, [
        h('span', {className: 'white'}, completion + '%'),
        h('span', {className: 'grey'}, ' Completion')
      ]),
    ]),
    h(Button, {theme: 'secondary', className: 'button', disabled: unsaved || !interpretable || isCalcZones, onClick: calculateZones}, isCalcZones ? 'CALCULATING ZONES...' : 'CALCULATE ZONES'),
    h(Button, {theme: 'primary', className: 'button', disabled: !canSave || isSaving, onClick: saveArea}, isSaving ? 'SAVING...' : 'SAVE AREA'),
    h('span', {className: 'link skip-area', onClick: () => {
      if (confirm('Mark area as deferred and go to next area?')) skipArea(area);
    }}, 'Skip Area')
  ])
}

module.exports = StatusBar;
