const t = require('tcomb')
const Base = require('./base')

exports.Type = Base.Type.extend({
  values: t.list(t.Object)
}, {
  name: 'SignAttrConfig.Selector',
  defaultProps: {
    values: [],
  }
})

exports.validateAttributeValue = function validateNumberAttributeValue (attributeConfig, attributeValue) {
  var error = Base.validateAttributeValue(attributeConfig, attributeValue)
  if (error) return error
}
