const h = require('react-hyperscript')
const classes = require('classnames')
const sf = require('sheetify')
sf('./button.css')

module.exports = Button

const themes = {
  primary: {
    normal: 'bg-green white hover-bg-dark-green b--transparent'
  },
  secondary: {
    normal: 'bg-blue white hover-bg-dark-blue b--transparent'
  },
  warning: {
    normal: 'bg-red white hover-bg-dark-red b--transparent'
  },
  default: {
    normal: 'black hover-bg-black-20 b--black'
  }
}

function Button ({
  className,
  children,
  disabled,
  theme,
  ...rest
}) {
  theme = themes[theme] || themes.default

  return h('a', {
    className: classes(
      className,
      'f5 pointer no-underline black inline-flex items-center justify-center ph3 pv2 ba',
      classes(theme.normal, 'pointer bg-animate'),
      disabled ? 'disabled' : null
    ),
    ...rest
  }, children)
}
