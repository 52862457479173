module.exports = {
  formatDatapoint,
  formatSign,
  combineDatapoint,
  createSign
}

// DEPRECATED
/**
 * Takes legacy API format for a data point and modifies it to the new API format
 * @param rawDataPoint
 * @returns {{id: null, jurisdiction: {id: string}, latitude: (*|number|Number), longitude: (*|Number), rawSignId: *, rawSignUrl: string, status: string, signs: *}}
 */
// function formatDataPoint (rawDataPoint) {
//   return {
//     id: null,
//     jurisdiction: rawDataPoint.jurisdiction || {id: 'Q77mSPI7gLcvT53ofCU7Fi0hd69XL4jq'}, // FIXME: Inserting Denver County for now
//     latitude: rawDataPoint.latitude,
//     longitude: rawDataPoint.longitude,
//     rawSignId: rawDataPoint.id,
//     rawSignUrl: `http://data-collection.spotparking.com.au.s3.amazonaws.com/production/raw-data-points/${rawDataPoint.imageKey}-original.jpg`,
//     status: 'DATA_COLLECTED',
//     signs: rawDataPoint.parkingSigns && rawDataPoint.parkingSigns.map((sign) => formatSign(sign))
//   }
// }

function formatDatapoint(rawDataPoint) {
  return {
    id: rawDataPoint.pamReferenceId || null,
    jurisdiction: rawDataPoint.jurisdiction || null,// || {id: 'Q77mSPI7gLcvT53ofCU7Fi0hd69XL4jq'},
    latitude: rawDataPoint.locations.captured.latitude,
    longitude: rawDataPoint.locations.captured.longitude,
    preprocessedSigns: rawDataPoint.preprocessedSigns,
    rawSignId: rawDataPoint.id,
    rawSignUrl: `https://s3-ap-southeast-2.amazonaws.com/data-collection.spotparking.com.au/production/raw-data-points/${rawDataPoint.imageKey}-md.jpg`,
    isPublicPhoto: rawDataPoint.isPublicPhoto,
    status: rawDataPoint.status,
    signs: formatPreprocessedSigns(rawDataPoint)
  }
}

function combineDatapoint(dp, pamDp) {
  if (!pamDp) return dp;
  dp.jurisdiction = pamDp.jurisdiction;
  dp.signs = pamDp.signs;
  dp.datazones = pamDp.datazones;
  // dp.signs = dp.signs.map(sign => {
  //   return {priority: sign.priority, sign: sign};
  // });
  return dp;
}

function formatPreprocessedSigns({ preprocessedSigns, rawSignId }) {
  if (!preprocessedSigns) return [];
  return preprocessedSigns.map(sign => createSign(sign, rawSignId));
}

function createSign(preprocessedSign = {}, rawSignId) {
  return {
    priority: 0,
    attributes: {},
    rawSignId: rawSignId,
    category: null,
    directionLeft: false,
    directionRight: false,
    imageHash: preprocessedSign.imageKey,
    imageUrl: `https://s3-ap-southeast-2.amazonaws.com/data-collection.spotparking.com.au/production/parking-signs/${preprocessedSign.imageKey}-original.jpg`,
    notes: preprocessedSign.notes ? [preprocessedSign.notes] : []
  }
}


/**
 * Takes legacy API format for a sign and modifies it to the new API format
 * @param sign
 * @returns {{priority: number, sign: {attributes: {}, category: null, directionLeft: boolean, directionRight: boolean, imageHash: *, imageUrl: string, notes: *[]}}}
 */
function formatSign (sign) {
  return {
    priority: 0,
    attributes: {},
    rawSignId: sign.parkingSignId,
    category: null,
    directionLeft: false,
    directionRight: false,
    imageHash: sign.imageKey,
    imageUrl: `https://s3-ap-southeast-2.amazonaws.com/data-collection.spotparking.com.au/production/parking-signs/${sign.imageKey}-original.jpg`,
    notes: sign.notes ? [sign.notes] : []
  }
}
