const {combineReducers} = require('redux')
const {submissionReducer} = require('redux-submission')

const interpret = require('./interpret')
const auth = require('./auth')
const {
  area,
  areaIsLoading
} = require('./area');

const { currentDatapoint } = require('./datapoint');
const { config } = require('./config');
const { zones } = require('./zones');
const { schedule } = require('./schedule');

const appReducer = combineReducers({
  submission: submissionReducer,
  interpret,
  auth,
  area,
  areaIsLoading,
  currentDatapoint,
  config,
  zones,
  schedule
})

const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined
  }

  return appReducer(state, action)
}

module.exports = rootReducer
