const t = require('tcomb')

exports.Type = t.struct({
  type: t.enums.of('String'),
  value: t.String
}, {
  name: 'SignAttrValue.String',
  defaultProps: {
    type: 'String',
    value: ''
  }
})

exports.hasValue = (data) => typeof data.value === 'string' && data.value.length > 0
