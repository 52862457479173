const fetch = require('unfetch')
const join = require('url-join')
const ls = require('local-storage')
const config = require('../config')

module.exports = {
  login,
  logout,
  fetchApiKey,
  updateRawDatapoint,
  updateRawDatapointLocation,
  updateRawDatapointPhoto,
  deleteRawDatapoint,
  insertRawDatapoint,
  markSignAsProcessed,
  fetchAreasList,
  fetchAreaDetail,
  updateArea,
  resetZonesForArea,
  regenerateSnappedPoints,
  regenerateZones,
  getZones
}
window.dapi = module.exports

/**
 * Exchanges email and password for an API token, which it then stores to local storage
 * @param email
 * @param password
 * @returns {Promise<T>|Promise.<TResult>}
 */
function login ({email, password}) {
  return fetch(join(config.dataCollection.base, '1.0', 'api-keys'), {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      email,
      password
    })
  }).then(res => res.json()).then((data) => {
    const apiKey = data.apiKey
    apiKey && ls.set('apiKey', apiKey)
    return {apiKey: apiKey, errors: data.errors}
  })
}

/**
 * Fetches the next DataPoint to be interpreted, along with its signs
 * @returns {Promise<T>|Promise.<TResult>}
 */
function fetchNextDatapoint () {
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'processed-images', 'next'), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  }).then(res => res.json())
}

function updateRawDatapoint(datapoint, tags) {
  // console.log('update raw datapoint', datapoint);
  // datapoint.status = 'signs-preprocessed'; // reseting status to show pre-processed images
  // API: https://data-collection-api.spotparking.com.au/1.1/raw-data-points/status/:id/:status
  
  let payload = {};
  if (datapoint.pamReferenceId !== undefined) payload.pamReferenceId = datapoint.pamReferenceId;
  if (datapoint.zoneReferenceIds !== undefined) payload.zoneReferenceIds = datapoint.zoneReferenceIds;
  if (datapoint.jurisdictionReferenceId !== undefined) payload.jurisdictionReferenceId = datapoint.jurisdictionReferenceId;
  if (datapoint.isPublicPhoto !== undefined) payload.isPublicPhoto = datapoint.isPublicPhoto || false;
  if (tags !== undefined) payload.tags = tags;

  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'raw-data-points', 'status', datapoint.rawSignId, datapoint.status), {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify(payload)
    })
  })
  .catch(err => {
    console.log('Error updating datapoint', err);
    return { message: 'Error updating datapoint' }
  })
  .then(res => res.json())
  .then(res => {
    if (res.errors) throw JSON.stringify(res.errors);
  })
}

function insertRawDatapoint(datapointId) {
  // API: POST https://data-collection-api.spotparking.com.au/1.1/raw-data-points/insertAt/:id
  let payload = {};

  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'raw-data-points', 'insertAt', datapointId), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify(payload)
    })
  })
  .catch(err => {
    console.log('Error inserting datapoint', err);
    return { message: 'Error inserting datapoint' }
  })
  .then(res => res.json())
  .then(res => {
    if (res.errors) throw JSON.stringify(res.errors);
  })
}

function deleteRawDatapoint(datapointId) {
  // API: DELETE https://data-collection-api.spotparking.com.au/1.1/raw-data-points/:id
  let payload = {};

  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'raw-data-points', datapointId), {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify(payload)
    })
  })
  .catch(err => {
    console.log('Error deleting datapoint', err);
    return { message: 'Error deleting datapoint' }
  })
  .then(res => res.json())
  .then(res => {
    if (res.errors) throw JSON.stringify(res.errors);
  })
}

function updateRawDatapointLocation(datapoint, latitude, longitude) {

  // API: https://data-collection-api.spotparking.com.au/1.1/raw-data-points/locationUpdate/:id
  let payload = {};
  payload.latitude = latitude;
  payload.longitude = longitude;

  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'raw-data-points', 'locationUpdate', datapoint), {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify(payload)
    })
  })
  .catch(err => {
    console.log('Error updating datapoint location', err);
    return { message: 'Error updating datapoint location' }
  })
  .then(res => res.json())
  .then(res => {
    if (res.errors) throw JSON.stringify(res.errors);
  })
}

function updateRawDatapointPhoto(datapoint, imageBase64Data) {

  // API: https://data-collection-api.spotparking.com.au/1.1/raw-data-points/locationUpdate/:id
  let payload = {};
  payload.imageData = imageBase64Data;

  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.4', 'raw-data-points', 'replaceImage', datapoint), {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify(payload)
    })
  })
  .catch(err => {
    console.log('Error updating datapoint location', err);
    return { message: 'Error updating datapoint location' }
  })
  .then(res => res.json())
  .then(res => {
    if (res.errors) throw JSON.stringify(res.errors);
    return res;
  })
}

function updateArea(areaStart, status, tags) {
  let payload = {};
  if (areaStart.pamReferenceId) payload.pamReferenceId = areaStart.pamReferenceId;
  if (areaStart.zoneReferenceIds) payload.zoneReferenceIds = areaStart.zoneReferenceIds;
  if (areaStart.processingSide) payload.processingSide = areaStart.processingSide;
  if (areaStart.jurisdictionReferenceId) payload.jurisdictionReferenceId = areaStart.jurisdictionReferenceId;
  payload.isPublicPhoto = areaStart.isPublicPhoto;
 
  if (tags) payload.tags = areaStart.tags;
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'areas', 'status', areaStart.rawSignId, status), {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify(payload)
    })
  })
  .then(res => res.json())
  .catch(err => {
    console.log('Error updating area', err);
    return { message: 'Error updating area' }
  })
}

function resetZonesForArea(areaStart) {

  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'areas', 'resetZones', areaStart), {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  })
  .then(res => res.json())
  .catch(err => {
    console.log('Error reseting zone information', err);
    return { message: 'Error resetting zones information' }
  })
}

function regenerateSnappedPoints(areaReferenceId, processingSide, forwardDirection = true, distance = 3) {
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'utils', 'regenerateSnappedPoints', `${areaReferenceId}?forward=${forwardDirection}&distance=${distance}&processingSide=${processingSide.toUpperCase()}`), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  })
  .then(res => res.json())
  .catch(err => {
    console.log('Error regenerating snapped points information', err);
    return { message: 'Error regenerating snapped points information' }
  })
}

function regenerateZones(areaReferenceId) {
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'schedules', 'regenerateZones', `${areaReferenceId}`), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  })
  .then(res => res.json())
  .catch(err => {
    console.log('Error regenerating zones information', err);
    return { message: 'Error regenerating zones information' }
  })
}

/**
 * Marks a sign as processed, when given a sign id
 * @param signId
 * @returns {Promise.<TResult>}
 */
function markSignAsProcessed (signId) {
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.0', 'parking-signs', signId, 'properties'), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      },
      body: JSON.stringify({
        parkingSignProperties: {
          category: 'NEW_DATABASE',
          leftArrow: false,
          rightArrow: false,
          areaCode: '',
          permitHoldersExcepted: false,
          carShareVehiclesExcepted: false,
          motorbikeOnly: false,
          damaged: false,
          notes: ''
        }
      })
    })
  }).then(res => res.json())
}

/**
 * Deletes API key from localStorage
 */
function logout () {
  return Promise.resolve().then(() => ls.remove('apiKey'))
}

/**
 * Fetches API key from localStorage
 */
function fetchApiKey () {
  return Promise.resolve().then(() => ({apiKey: ls.get('apiKey')}))
}

function fetchAreasList(status) {
  status = status || 'started,not-started,interpreted,deferred';
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'areas', 'outline', status), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  }).then(res => res.json())
}

function fetchAreaDetail(areaId) {
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'areas', 'detail', areaId), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  }).then(res => res.json())
}

function getZones(areaReference) {
  return fetchApiKey().then(({ apiKey }) => {
    return fetch(join(config.dataCollection.base, '1.1', 'schedules', 'identifyZones', `${areaReference}?interval=1&duration=30240`), {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-API-Token': apiKey ? apiKey.token : ''
      }
    })
  }).then(res => res.json())
}