const React = require('react')
const h = require('react-hyperscript')
const getFormData = require('form-data-set/element')
const {connect} = require('react-redux')
const {login} = require('../actions')

const SignIn = React.createClass({
  handleSubmit (ev) {
    const {login, router} = this.props

    ev.preventDefault()
    const data = getFormData(ev.target)
    login(data).then((result) => {
      if (result.errors) {
        return
      }

      // Send user to the /image-interpret page after logging them in
      router.push('/image-interpret')
    })
  },

  componentDidMount () {
    const {auth, router} = this.props
    if (auth.apiKey) {
      router.push('/image-interpret')
      return
    }
  },

  render () {
    const {auth} = this.props
    const {errors} = auth

    return h('div.mw6-ns.w-100.v-mid.ba.b--light-gray.pa5.ph5-ns', {style: {boxSizing: 'border-box', margin: '64px auto'}}, [
      h('h1.f3.fw4.gray.tc', ['Login to Spot Parking DIT']),
      h('h4.f6.fw1.f-subheadline.silver.tc.mb5', ['Enter your details below']),
      h('span.f6.lh-copy.dark-red', [errors ? errors.global[0] : null]),
      h('form', {
        onSubmit: this.handleSubmit
      }, [
        h('div', {style: {boxSizing: 'border-box', marginBottom: '32px'}}, [
          h('label.f7.b.db.mb2.black-60.ttu.tracked', {htmlFor: 'email', type: 'email'}, ['Email Address']),
          h('input.input-reset.ba.b--black-20.black-60.pa2.mb2.db.w-100', {type: 'email', name: 'email', placeholder: 'Enter your email'}),
          h('span.f6.lh-copy.dark-red')
        ]),
        h('div', {style: {boxSizing: 'border-box'}}, [
          h('label.f7.b.db.mb2.black-60.ttu.tracked', {htmlFor: 'password', type: 'password'}, ['Password']),
          h('input.input-reset.ba.b--black-20.black-60.pa2.mb2.db.w-100', {type: 'password', name: 'password', placeholder: 'Enter your password'}),
          h('span.f6.lh-copy.dark-red')
        ]),
        h('button.f6.w-100.tc.link.dim.ph3.pv3.dib.white', {
          type: 'submit',
          style: {boxSizing: 'border-box', marginTop: '56px', backgroundColor: '#00e5c7', cursor: 'pointer'}
        }, [
          'LOGIN'
        ])
      ])
    ])
  }
})

function mapStateToProps (state) {
  return {auth: state.auth}
}

function mapDispatchToProps (dispatch) {
  return ({
    login: (email, password) => {
      const action = login(email, password)
      dispatch(action)
      return action.payload
    }
  })
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(SignIn)
