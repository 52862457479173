const React = require('react')
const h = require('react-hyperscript')
const Nav = require('../components/nav')
const {connect} = require('react-redux')
const { fetchApiKey, logout } = require('../actions')

const App = React.createClass({

  componentDidMount () {
    const { auth, fetchApiKey } = this.props
    if (!auth.loaded) {
      fetchApiKey()
    }
  },

  handleLogoutClick (e) {
    const { logout, router } = this.props
    e.preventDefault()
    logout().then(() => router.push('/login'))
  },

  render () {
    const { auth } = this.props

    // localStorage.setItem('state', JSON.stringify(this.props.state))

    return auth.loaded ? h('div', {className: 'absolute absolute--fill'}, [
      h(Nav, {loggedIn: Boolean(auth.apiKey), onLogoutClick: this.handleLogoutClick}),
      h('div', {}, this.props.children)
    ]) : null
  }
})

function mapStateToProps (state) {
  return {
    state,
    auth: state.auth
  }
}

function mapDispatchToProps (dispatch) {
  return ({
    fetchApiKey: () => {
      const action = fetchApiKey()
      dispatch(action)
      return action.payload
    },
    logout: () => {
      const action = logout()
      dispatch(action)
      return action.payload
    }
  })
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(App)
