const t = require('tcomb')
const h = require('react-hyperscript')
import Select from 'react-select';
const arrayToHash = require('array-to-hash')
const {propTypes} = require('tcomb-react')

const {IconButton, ToggleButton} = require('../../../components/button')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')
const SubtypeSelect = require('./_subtype-select')
const Mutator = require('../../../util/mutator')

module.exports = DayRangeComponent

DayRangeComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Base.Type,
  data: SignAttrValue.DayRange.Type
})

function DayRangeComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  return h('div', rest, [
    h(SubtypeSelect, {
      label: 'Day Range Type',
      value: data.subtype,
      options: SignAttrValue.DayRange.SUBTYPES,
      onChange: (subtype) => {
        mutator.onSetValue(['subtype'], subtype)
        if (subtype !== 'WEEKDAY_OF_WEEK') {
          mutator.onSetValue(['weekDay'], [])
        }
        if (subtype !== 'WEEKDAY_OF_MONTH') {
          mutator.onSetValue(['weekDayOfMonth'], [])
        }
        if (subtype !== 'SPECIFIC_DAYS') {
          mutator.onSetValue(['days'], [])
        }
      }
    }),

    data.subtype === 'ALL_DAYS' ? undefined
      : data.subtype === 'WEEKDAY_OF_WEEK' ? WeekDayOfWeekValues()
      : data.subtype === 'WEEKDAY_OF_MONTH' ? WeekDayOfMonthValues()
      : SpecificDays()
  ])

  function SpecificDays () {
    const selectedDays = arrayToHash(data.days)

    return h('div', {className: 'pa2'}, [
      ...SignAttrValue.DayRange.DAYS.map(function (day, index) {
        const isSelected = day in selectedDays

        return h(ToggleButton, {
          selected: isSelected,
          onClick: () => isSelected
            ? mutator.onRemoveValue(['days'], data.days.indexOf(day))
            : mutator.onOrderedPushValue(['days'], day)
        }, day)
      })
    ])
  }
  
  function WeekDayOfWeekValues () {
    const selectedWeekDays = arrayToHash(data.weekDay)
    let onlyWeekDays = false;
    let onlyWeekEnds = false;

    let includeSchoolDays = 'SchoolDay' in selectedWeekDays;
    let includeHolidays = 'Holiday' in selectedWeekDays;
    let excludeSchoolDays = 'Exclude-SchoolDay' in selectedWeekDays;
    let excludeHolidays = 'Exclude-Holiday' in selectedWeekDays;

    if (data.weekDay.length === 5) {
      if (
        ('Monday' in selectedWeekDays) &&
        ('Tuesday' in selectedWeekDays) &&
        ('Wednesday' in selectedWeekDays) &&
        ('Thursday' in selectedWeekDays) && 
        ('Friday' in selectedWeekDays)
      ) {
        onlyWeekDays = true;
      }
    }

    if (data.weekDay.length === 2) {
      if (
        ('Saturday' in selectedWeekDays) &&
        ('Sunday' in selectedWeekDays)

      ) {
        onlyWeekEnds = true;
      }
    }
    
    return [h('div', {className: 'mt2'}, [
      ...SignAttrValue.DayRange.WEEKDAYS.map(function (weekDay, index) {
        const isSelected = weekDay in selectedWeekDays
        
        return h(ToggleButton, {
          key: index,
          selected: isSelected,
          onClick: () => isSelected
            ? mutator.onRemoveValue(['weekDay'], data.weekDay.indexOf(weekDay))
            : mutator.onOrderedPushValue(['weekDay'], weekDay)
        }, weekDay.substring(0, 3))
      })
    ]),
    h('div', {className: 'mt2'}, [h('span', {className: 'mh2'}, 'Quick select: '),
      h(ToggleButton, {
        key: 1,
        selected: onlyWeekDays,
        className: 'w4',
        onClick: () => onlyWeekDays 
          ? mutator.onSetValue(['weekDay'], [])
          : mutator.onSetValue(['weekDay'], ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])
        }, "Weekdays"),
        h(ToggleButton, {
          key: 2,
          selected: onlyWeekEnds,
          className: 'w4',
          onClick: () => onlyWeekEnds 
            ? mutator.onSetValue(['weekDay'], [])
            : mutator.onSetValue(['weekDay'], ['Saturday', 'Sunday'])
          }, "Weekends")
    ]),
    h('div', {className: 'mt2'}, [h('span', {className: 'mh2'}, 'Including:  '),
      h(ToggleButton, {
        key: 1,
        selected: includeSchoolDays,
        className: 'w4',
        onClick: () => { excludeSchoolDays ? null : includeSchoolDays 
          ? mutator.onRemoveValue(['weekDay'], data.weekDay.indexOf('SchoolDay'))
          : mutator.onOrderedPushValue(['weekDay'], 'SchoolDay') 
        }
        }, "School Days"),
        h(ToggleButton, {
          key: 2,
          selected: includeHolidays,
          className: 'w4',
          onClick: () => { excludeHolidays ? null : includeHolidays 
            ? mutator.onRemoveValue(['weekDay'], data.weekDay.indexOf('Holiday'))
            : mutator.onOrderedPushValue(['weekDay'], 'Holiday')
          }
          }, "Holidays"),
    ]),
    h('div', {className: 'mt2'}, [h('span', {className: 'mh2'}, 'Excluding: '),
    h(ToggleButton, {
      key: 1,
      selected: excludeSchoolDays,
      className: 'w4',
      onClick: () => { includeSchoolDays ? null : excludeSchoolDays 
        ? mutator.onRemoveValue(['weekDay'], data.weekDay.indexOf('Exclude-SchoolDay'))
        : mutator.onOrderedPushValue(['weekDay'], 'Exclude-SchoolDay') 
      }
      }, "School Days"),
      h(ToggleButton, {
        key: 2,
        selected: excludeHolidays,
        disabled: includeHolidays,
        className: 'w4',
        onClick: () => { includeHolidays ? null : excludeHolidays 
          ? mutator.onRemoveValue(['weekDay'], data.weekDay.indexOf('Exclude-Holiday'))
          : mutator.onOrderedPushValue(['weekDay'], 'Exclude-Holiday')
        }
        }, "Holidays"),
  ]),
  ]
  }

  function WeekDayOfMonthValues () {
    return h('div', {className: 'mt2'}, [
      ...data.weekDayOfMonth.map(function (value, index) {
        const itemPath = ['weekDayOfMonth', index]

        return h('div', {
          key: index,
          className: 'pv1 flex items-center justify-between'
        }, [
          h('div', [
            h(IconButton, {
              className: 'mv1 white bg-red',
              onClick: () => {
                if (!window.confirm('Are you sure you want to remove this value?')) return
                mutator.onRemoveValue(['weekDayOfMonth'], index)
              }
            }, 'x'),
            h('span', {className: 'mh2'}, 'Days')
          ]),
          h('div', {
            className: 'flex items-center'
          }, [
            h(Select, {
              className: 'w4 mr2',
              placeholder: 'Choose...',
              clearable: false,
              value: value.weekDay,
              options: SignAttrValue.DayRange.WEEKDAYS.map((weekDay) => {
                return {value: weekDay, label: weekDay}
              }),
              onChange: (item) => mutator.onSetValue(
                itemPath.concat('weekDay'),
                item.value
              )
            }),
            h('span', {className: 'mh2'}, 'Week#'),
            h(Select, {
              className: 'w4 mr2',
              clearable: false,
              value: value.dayNumber,
              options: [1, 2, 3, 4].map((number) => {
                return {value: number, label: number}
              }),
              onChange: (item) => mutator.onSetValue(
                itemPath.concat('dayNumber'),
                item.value
              )
            })
          ])
        ])
      }),

      h(IconButton, {
        className: 'mv1 white bg-blue',
        onClick: () => mutator.onPushValue(
          ['weekDayOfMonth'],
          SignAttrValue.DayRange.WeekDayOfMonthType({})
        )
      }, '+')
    ])
  }
}
