const h = require('react-hyperscript')

module.exports = StructureValue

function StructureValue ({
  attributeConfig,
  children
}) {
  return h('div', {className: 'w-100'}, [
    ...children.map(mapChild)
  ])

  function mapChild (child) {
    return h('div', {className: 'pa2 ba b--black-20'}, child)
  }
}
