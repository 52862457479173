const h = require('react-hyperscript')
const classes = require('classnames')
const sf = require('sheetify')
sf('./line-plot.css')

module.exports = {
  LinePlot,
}

function LinePlot ({
  start,
  datapoints,
  end,
  selectDatapoint,
  processingSide,
  currentDatapoint,
  zones,
  onShowZone,
  collapsed,
  unsaved,
  selectedZone
}) {
  if (processingSide === 'RIGHT') datapoints = datapoints.slice().reverse();

  let dps = datapoints.reduce((acc, dp) => {
    acc[dp.rawSignId] = true;
    return acc;
  }, {});

  return h('div', {className: 'area-line-container'}, [
    h('div', {className: 'datapoints'}, [
      datapoints.map((dp, i) => h(Datapoint, {
        datapoint: dp,
        key: dp.rawSignId,
        currentDatapoint,
        selectDatapoint: () => {
          if (unsaved) {
            if (confirm('Discard unsaved changes?')) {
              selectDatapoint(dp)
            }
          }
          else selectDatapoint(dp)
        },
        lastDp: i === datapoints.length - 1,
        showSigns: !collapsed,
        hasZones: zones.length
      }))
    ]),
    zones ? h('div', {className: 'zones-container'}, [
      zones.map((zone, i) => h(Zone, {
        zone,
        key: i,
        dps,
        index: i,
        onShowZone,
        selectedZone
      }))
    ]) : null
  ])
}

function Datapoint ({
  datapoint,
  currentDatapoint,
  selectDatapoint,
  lastDp,
  showSigns,
  hasZones
}) {
  let signObjs = datapoint.signs.slice().sort((a,b) => a.category < b.category);
  let additional;
  let numSigns = 3;
  if (signObjs.length > numSigns) {
    additional = signObjs.length - numSigns;
    signObjs = signObjs.slice(0, numSigns);
  }
  let signs = null;
  if (showSigns && datapoint.signs) {
    signs = h('div', {className: 'sign-container'}, [
      signObjs.map(sign => {
        if (sign.category) {
          return h(Sign, {
            sign: sign,
            key: sign.id  || JSON.stringify(sign)
          });
        }
        else return null;
      }),
      additional ? h('div', {className: 'sign-additional'}, 'PLUS ' + additional + ' MORE') : null
    ]);
  }
  return h('div', {className: 'dp-container'}, [
    signs,
    h('div', {className: classes('dp-marker', datapoint.id ? 'interpreted' : null), onClick: selectDatapoint}),
    lastDp ? h('div', {className: 'spacer'}) : h('div', {className: classes('dp-line', {'no-border': hasZones})}),
    h('div', {className: classes('dp-label', {'selected': currentDatapoint.rawSignId === datapoint.rawSignId}), onClick: selectDatapoint}, 'DP'+datapoint.rawSignId),
    h('div', {className: 'hidden'}, datapoint.status)
  ])
}

function Zone ({
  zone,
  dps,
  index,
  onShowZone,
  selectedZone
}) {

  let zoneLength = zone.datapoints.length - 1;
  // let zoneLength = 0;
  // let zoneDps = zone.datapoints.slice().sort((a,b) => a > b);
  // for (let dpId = zoneDps[0]; dpId < zoneDps[zoneDps.length - 1]; dpId++) {
  //   if (dps[dpId]) zoneLength++;
  // }

  let lengthClass = 'wide-' + zoneLength;
  let colorClass;
  if (zone.error) colorClass = 'zone-error';
  else colorClass = 'zone-interpreted-' + (index % 2 + 1);
  return h('div', {className: 'zone'}, [
    h('div', {
      className: classes('zone-line', lengthClass, colorClass, {'not-selected': selectedZone && JSON.stringify(selectedZone) !== JSON.stringify(zone) }),
      onClick: () => {onShowZone(zone)}
    }),
    // h('div', {className: 'zone-label'}, JSON.stringify(zone.datapoints)),
  ])
}

function Sign({
  sign
}) {
  let leftArrow = h('span', {className: 'sign-left-arrow'}, sign.directionLeft ? '←' : '');
  let rightArrow = sign.directionRight ? h('span', {className: 'sign-right-arrow'}, '→') : null;
  let category = sign.category.split('_').join(' ');
  return h('div', {className: 'sign'}, [
    leftArrow,
    h('div', {className: 'sign-category'}, `(${sign.priority}) ${category}`),
    rightArrow
  ]);
}