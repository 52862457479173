const React = require('react')
const { connect } = require('react-redux')
const h = require('react-hyperscript')
const classes = require('classnames')

const sf = require('sheetify')
sf('./index.css');

const ZoneErrorView = React.createClass({
    getInitialState,
    render
})

function getInitialState() {
    return ({})
}

function render() {

    let {errors} = this.props;

    return (
        h('div', { className: classes('flex-auto flex-column flex zone-error-div')},
        errors.map(detail => {
            if (detail.added === true) {
                return h('pre', { className: classes('added', 'mt0', 'mb0') }, detail.value);
            }
            if (detail.removed === true) {
                return h('pre', { className: classes('removed', 'mt0', 'mb0') }, detail.value);
            }
            return h('pre', { className: classes('mt0', 'mb0')}, detail.value)
        })
    )
    )
}

function mapStateToProps(state, props) {
    return ({});
  }
  
  function mapDispatchToProps(dispatch) {
    return ({})
  }
  
  module.exports = connect(mapStateToProps, mapDispatchToProps)(ZoneErrorView)
  

