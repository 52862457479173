const h = require('react-hyperscript')
const { Button } = require('../../components/button')
const sf = require('sheetify');
sf('./header.css')

module.exports = (props) => {
  let status;
  switch (props.datapoint.rawSignId) {
    case props.areaStart.id:
      status = 'Area Start'
      break;

    case props.areaEnd.id:
      status = 'Area End'
      break;

    default:
      status = 'Status: ' + props.datapoint.status
  }

//  console.log("HEADER props = ", props);

  return h('div', {className: 'header-container'}, [
    h('div', {className: 'flex align-items-center'}, [
      h('h1', {className: 'f4 mr2'}, 'Interpret Data Point ' + props.datapoint.rawSignId),
      h('div', {}, status),
      h('div', {className: 'flex-align-right'}, props.saveStatus),
      h(Button, {
        className: 'ma2',
        disabled: (props.datapoint.rawSignId === props.areaEnd.id),
        theme: 'secondary',
        onClick: () => props.onAddDatapoint({rawSignId: props.datapoint.rawSignId})
      }, 'Add Data Point'),
      h(Button, {
        className: 'ma2',
        disabled: (props.datapoint.rawSignId === props.areaStart.id) || (props.datapoint.rawSignId === props.areaEnd.id),
        theme: 'warning',
        onClick: () => props.onDeleteDatapoint({rawSignId: props.datapoint.rawSignId})
      }, 'Delete Data Point'),
      h(Button, {
        className: 'ma2',
        theme: 'primary',
        onClick: () => props.onSaveDatapoint({next: true})
      }, 'Save Data Point'),
      h('a', {href: '#', onClick: onResetDatapoint}, 'Reset Datapoint')
    ])
  ])

  function onResetDatapoint() {
    if(window.confirm('ALL SAVED signs will be lost. Continue?')) {
      props.resetDatapoint(props.datapoint);
    }
  }
}