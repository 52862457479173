const api = require('../api')

module.exports = {
  fetchAllJurisdictions,
  fetchJurisdictionSignConfigs,
}

function fetchAllJurisdictions() {
  return (dispatch) => {
    return api.pam.getAllJurisdictions().then(jurisdictions => {
      dispatch(setJurisdictions(jurisdictions));
    })
  }
}

function setJurisdictions(jurisdictions) {
  return {
    type: 'SET_JURISDICTIONS',
    jurisdictions
  }
}

// function fetchCategories(jurisdictionId) {
//   return api.pam.getCategoriesByJurisdiction(jurisdictionId)
// }

// function fetchSignConfig({jurisdiction, category}) {
//   return api.pam.getSignConfigByJurisdictionAndCategory(jurisdiction.id, category).then((signConfig) => {
//     if (!signConfig.structures) {
//       signConfig.structures = {}
//     }

//     return signConfig
//   })
// }

function fetchJurisdictionSignConfigs(jurisdictionId) {
  return (dispatch, getState) => {

    if (!jurisdictionId) jurisdictionId = getState().config.areaJurisdiction.id;
    return api.pam.getSignConfigsByJurisdiction(jurisdictionId).then(results => {

      let signConfigs = results.signConfigs;

      let jurisdictionDictionary = {};
      signConfigs.forEach(signConfig => {
        if (jurisdictionDictionary[signConfig.jurisdiction.id] === undefined) jurisdictionDictionary[signConfig.jurisdiction.id] = [];
        jurisdictionDictionary[signConfig.jurisdiction.id].push(signConfig);
      });

      // establish the sign heirarchy
      let jurisdictionHierarchy = results.jurisdictionHierarchy;

      let signConfigDictionary = {};
      for (let i = jurisdictionHierarchy.length - 1; i >= 0; i--) {
        let jursdictionSignConfigs = jurisdictionDictionary[jurisdictionHierarchy[i]];
        if (jursdictionSignConfigs) {
          jursdictionSignConfigs.forEach(signConfig => {
            signConfigDictionary[signConfig.category] = signConfig;
          });
        }
      }
      
      let newSignConfigs = [];
      Object.keys(signConfigDictionary).forEach(category => {
        newSignConfigs.push(signConfigDictionary[category]);
      });

      dispatch(setSignConfigs(newSignConfigs));
    })
  }
}

function setSignConfigs(signConfigs) {
  return {
    type: 'SET_SIGN_CONFIGS',
    signConfigs
  }
}