const titleCase = require('title-case')
const h = require('react-hyperscript')
import Select from 'react-select';

module.exports = SubtypeSelect

function SubtypeSelect ({label, value, options, onChange}) {
  return h('div', {
    className: 'flex items-center'
  }, [
    h('div', {className: 'w-50'}, titleCase(label)),
    h(Select, {
      className: 'w-50',
      clearable: false,
      placeholder: 'Choose a type...',
      value,
      options: options.map((type) => {
        if (typeof type != 'object')
          return {value: type, label: titleCase(type)}
        return { value: type.value, label: type.label ? type.label : titleCase(type.value)}
      }),
      onChange: ({value}) => onChange(value)
    })
  ])
}
