const t = require('tcomb')
const h = require('react-hyperscript')
const moment = require('moment')
const TimePicker = require('rc-time-picker')
const {propTypes} = require('tcomb-react')

const Mutator = require('../../../util/mutator')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')

module.exports = TimeRangeComponent

TimeRangeComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Base.Type,
  data: SignAttrValue.TimeRange.Type
})

function TimeRangeComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  const isAllDay = data.startTime === moment().startOf('day').minutes() &&
        data.endTime === 1439

  return h('div', rest, [
    h('div', {className: 'flex items-center'}, [
      h('div', {className: 'mr2'}, 'Time Range'),

      h(TimePicker, {
        showSecond: false,
        value: moment().startOf('day').add(data.startTime, 'minutes'),
        onChange: (startMoment) => mutator.onSetValue(['startTime'], getMinutes(startMoment))
      }),

      h('span', {className: 'mh2'}, 'to'),

      h(TimePicker, {
        showSecond: false,
        value: moment().startOf('day').add(data.endTime, 'minutes'),
        onChange: (endMoment) => mutator.onSetValue(['endTime'], getMinutes(endMoment))
      }),

      h('label.flex.items-center.ml3', [
        h('input', {
          type: 'checkbox',
          checked: isAllDay,
          onClick: () => {
            mutator.onSetValue(['startTime'], isAllDay ? 0 : moment().startOf('day').minutes())
            mutator.onSetValue(['endTime'], isAllDay ? 0 : 1439)
          }
        }),
        h('span.ml2', 'All Day')
      ])
    ])
  ])

  function getMinutes (momentInstance) {
    return moment(momentInstance).hours() * 60 + moment(momentInstance).minutes()
  }
}
