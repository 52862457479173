const t = require('tcomb')
const assert = require('assert')
const sortOn = require('sort-on')
const Sign = require('../sign')
const SignConfig = require('../sign-config')

module.exports = function walkSignAttributes (sign, signConfig, callback) {
  Sign.Type.is(sign)
  SignConfig.Type.is(signConfig)
  t.Function.is(callback)

  return sortAttributeKeys(signConfig.attributes)
    .map((key) => recurse(
      signConfig.attributes[key],
      sign.attributes[key],
      [key]
    ))

  function sortAttributeKeys (attributes) {
    return sortOn(
      Object.keys(attributes),
      (key) => attributes[key].order
    )
  }

  function recurse (attributeConfig, attributeValue, path) {
    attributeConfig = attributeConfig || {}
    attributeValue = attributeValue || {}

    const isArray = attributeConfig.type === 'Array'
    const isStructure = typeof attributeConfig.fields === 'object'
    const type = isArray ? 'Array'
      : isStructure ? 'Structure'
      : 'Primitive'

    if (isArray) attributeValue.type = 'Array'
    else if (isStructure) attributeValue.type = 'Structure'
    else if (!attributeValue.type) attributeValue.type = attributeConfig.type

    return callback({
      path,
      sign,
      signConfig,
      attributeConfig,
      attributeValue,
      type,
      yieldChildren: type === 'Primitive'
        ? null
        : yieldChildren
    })

    function yieldChildren () {
      if (isStructure) {
        return sortAttributeKeys(attributeConfig.fields || {}).map((key) => {
          return recurse(attributeConfig.fields[key], attributeValue.fields[key], path.concat(['fields', key]))
        })
      }

      if (isArray) {
        const structure = signConfig.structures[attributeConfig.structure]

        assert(attributeConfig.structure, `walkSignAttributes [${path.join('.')}]: attributeConfig.structure must be string`)
        assert.equal(typeof structure, 'object', `walkSignAttributes [${path.join('.')}]: signConfig.structures[attributeConfig.structure] must exist`)

        return (attributeValue.values || []).map((value, index) => {
          return recurse(structure, value, path.concat(['values', index]))
        })
      }
    }
  }
}
