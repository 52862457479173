const t = require('tcomb')
const h = require('react-hyperscript')
import Select from 'react-select';
const SubtypeSelect = require('./_subtype-select')
const {propTypes} = require('tcomb-react')

const Mutator = require('../../../util/mutator')
const {SignAttrValue, SignAttrConfig, SignConfigStructure} = require('../../../schema')

const NumberComponent = require('./number');

module.exports = TariffComponent

TariffComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Base.Type,
  data: SignAttrValue.TariffType.Type
})

function TariffComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })


  if (config.currency) {
    if (data.currency !== config.currency) {
      mutator.onSetValue(['currency'], config.currency);
    }
  }

  return h('div', rest, [
    h(SubtypeSelect, {
      label: 'Tariff Type',
      value: data.subtype,
      options: SignAttrValue.TariffType.SUBTYPES,
      onChange: (subtype) => {
        switch (subtype) {
          case 'PRO_RATED':
          case 'FIXED':
            mutator.onSetValue(['cappedCharge'], 0);
            break;
          case 'FREE':
            mutator.onSetValue(['cappedCharge'], 0);
            mutator.onSetValue(['displayCharge'], 0);
            break;
          default:
            break;
        }
        mutator.onSetValue(['subtype'], subtype);
      }
    }),
    ((data.subtype != 'FREE') && (data.subtype != 'STEPPED_BY_DURATION') && (data.subtype != 'SPECIAL_TARIFF')) ? h(SubtypeSelect, {
      label: 'Currency',
      value: data.currency || config.currency,
      options: config.currency ? [config.currency] : SignAttrValue.TariffType.CURRENCIES,
      onChange: (currency) => {
        mutator.onSetValue(['currency'], currency);
      }
    }) : null,
    ((data.subtype != 'FIXED') && (data.subtype != 'FREE') && (data.subtype != 'STEPPED_BY_DURATION') && (data.subtype != 'SPECIAL_TARIFF')) ? h(NumberInput, {
      label: 'Min Charge/Unit',
      data: data.minimumChargeUnit,
      handleChange: (value) => {
        mutator.onSetValue(['minimumChargeUnit'], value);
      }
    }) : null,
    ((data.subtype != 'FIXED') && (data.subtype != 'FREE') && (data.subtype != 'STEPPED_BY_DURATION') && (data.subtype != 'SPECIAL_TARIFF')) ? h(NumberInput, {
      label: 'Charge Interval',
      data: data.chargeInterval,
      handleChange: (value) => {
        mutator.onSetValue(['chargeInterval'], value);
      }
    }) : null,
    ((data.subtype != 'FREE') && (data.subtype != 'STEPPED_BY_DURATION') && (data.subtype != 'SPECIAL_TARIFF'))? h(NumberInput, {
      label: 'Display Charge',
      data: data.displayCharge,
      handleChange: (value) => {
        mutator.onSetValue(['displayCharge'], value);
      }
    }) : null,
    ((data.subtype != 'FIXED') && (data.subtype != 'FREE') && (data.subtype != 'STEPPED_BY_DURATION') && (data.subtype != 'SPECIAL_TARIFF')) ? h(NumberInput, {
      label: 'Unit Size (min)',
      data: data.displayChargeUnitSize,
      handleChange: (value) => {
        mutator.onSetValue(['displayChargeUnitSize'], value);
      }
    }): null,
    (data.subtype == 'PRO_RATED_WITH_DAILY_CAP' || data.subtype == 'PRO_RATED_WITH_PERIOD_CAP') ? h(NumberInput, {
      label: `Capped Amount`,
      data: data.cappedCharge,
      handleChange: (value) => {
        mutator.onSetValue(['cappedCharge'], value);
      }
    }) : null,
  ])
}

function NumberInput({
  label,
  data,
  handleChange
}) {
  return h('div', {className: 'flex items-center'}, [
    h('div', {className: 'w-50'}, label),
    h('div', {className: 'w-50'}, [
      h('input', {
        className: 'w-100',
        type: 'number',
        value: data,
        onChange: (event) => {
          var value = event.target.value;
          if (value.substr(value.length - 1) === ".") value = `${value}0`;
          value = Number(value);
          if (isNaN(Number(value))) alert('Only numerical values allowed')
          else {
              handleChange(value);
          }
          // mutator.onSetValue(['displayCharge'], value);
          // debugger
        }
      })
    ])
  ])
}
