var _ = require('lodash');
var moment = require('moment');
var tz = require('moment-timezone');
var pluralize = require('pluralize');
var fs = require('fs');
var path = require('path');
var url = require('url');
var EventEmitter = require('events').EventEmitter;
var util = require('util');


function isAvailable(variable) {
  if ((!_.isUndefined(variable)) && (!_.isNull(variable))) return true;
  return false;
}

var Executor = function () {
  EventEmitter.call(this);
}

util.inherits(Executor, EventEmitter);

Executor.prototype = Object.create(EventEmitter.prototype);

Executor.prototype.getValue = function (obj, path) {
  return path.split(".").reduce(function(obj, name){ return obj[name]; }, obj);
}

Executor.prototype.compile = function(string) {
  var compiled = _.template(string);
  return  compiled;
}

Executor.prototype.execute = function(func, context) {
  return func(context);
}

Executor.prototype.run = function(string, context, libraries, job)
{
  var executeContext = _.clone(context);
  executeContext = _.assign(executeContext, libraries);

  var result = this.execute(this.compile(string), executeContext);

  if ((isAvailable(executeContext.state)) && (isAvailable(executeContext.state.promise))) {
    executeContext.state.promise.then(onSuccess, onError);
  } else onSuccess(executeContext.state.response);

  function onSuccess(data) {

    if ((isAvailable(data)) && (isAvailable(data.entity))) job.emit('completed', data.entity);
    else job.emit('completed', data);

    if ((isAvailable(executeContext.state)) && (isAvailable(executeContext.state.promise))) delete executeContext.state.promise;

  }

  function onError(err) {
    job.emit('error', err);
    if ((isAvailable(executeContext.state)) && (isAvailable(executeContext.state.promise))) delete executeContext.state.promise;
  }

  return result;
}


module.exports = function() {
  return new Executor();
};
