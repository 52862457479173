const t = require('tcomb')
const values = require('object-values')

const SignAttrValue = module.exports = {
  Boolean: require('./boolean'),
  DayRange: require('./day-range'),
  MonthRange: require('./month-range'),
  Number: require('./number'),
  String: require('./string'),
  TimeRange: require('./time-range'),
  TariffType: require('./tariff'),
  Rating: require('./rating'),
  Selector: require('./selector'),
  HTMLString: require('./html-string')
}

let PrimitiveTypes = values(SignAttrValue).map((i) => i.Type)

SignAttrValue.Primitive = {
  Type: t.union(PrimitiveTypes, 'SignAttrValue.Primitive')
}

SignAttrValue.Structure = {
  Type: t.struct({
    type: t.enums.of('Structure'),
    fields: t.dict(t.String, SignAttrValue.Primitive.Type)
  }, {
    defaultProps: {
      type: 'Structure',
      fields: {}
    }
  })
}

SignAttrValue.Array = {
  Type: t.struct({
    type: t.enums.of('Array'),
    values: t.list(SignAttrValue.Structure.Type)
  }, {
    name: 'SignAttrValue.Array',
    defaultProps: {
      type: 'Array',
      values: []
    }
  })
}

// reassign Structure type as Array is now defined and should be available type within a structure
SignAttrValue.Structure.Type = t.struct({
  type: t.enums.of('Structure'),
  fields: t.dict(t.String, t.union([...PrimitiveTypes, SignAttrValue.Array.Type]))
}, {
  defaultProps: {
    type: 'Structure',
    fields: {}
  }
})




