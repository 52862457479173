const h = require('react-hyperscript')
const classes = require('classnames')
const {IconButton} = require('../button')

module.exports = ImageControlsComponent

ImageControlsComponent.createProperties = (props) => ({
  degrees: 0,
  zoom: 100,
  ...props || {}
})

function ImageControlsComponent ({
  className,
  degrees,
  zoom,
  onChange,
  ...rest
}) {
  degrees = degrees || 0
  zoom = zoom || 100

  function handleChange (patch) {
    onChange({
      degrees,
      zoom,
      ...patch
    })
  }

  function rotateAndRound (delta) {
    var amount = Math.round(degrees / 90) * 90

    amount += delta

    return amount < 0 ? 360 + amount
      : amount >= 360 ? (amount % 360)
      : amount
  }

  return h('div', {
    className: classes(className, 'tc'),
    ...rest
  }, [
    h('div', {className: 'flex justify-center'}, [
      h('div', {className: 'flex flex-column items-center mr2'}, [
        h('div', [
          h(IconButton, {
            onClick: () => handleChange({degrees: rotateAndRound(-90)})
          }, h('i.fa.fa-rotate-left')),
          h(IconButton, {
            onClick: () => handleChange({degrees: rotateAndRound(90)})
          }, h('i.fa.fa-rotate-right'))
        ]),
        h('span', `${degrees}°`)
      ]),

      h('div', {className: 'flex flex-column items-center'}, [
        h('div', [
          h(IconButton, {
            onClick: () => handleChange({zoom: Math.max(10, zoom - 15)})
          }, h('i.fa.fa-search-minus')),
          h(IconButton, {
            onClick: () => handleChange({zoom: Math.min(350, zoom + 15)})
          }, h('i.fa.fa-search-plus'))
        ]),
        h('span', `${zoom}%`)
      ])
    ]),
    h('input', {
      className: 'w5',
      type: 'range',
      min: 0,
      max: 360,
      value: degrees,
      onChange: (ev) => handleChange({degrees: Number(ev.target.value)})
    })
  ])
}
