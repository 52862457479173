const React = require('react')
const h = require('react-hyperscript')
const classes = require('classnames')
const sf = require('sheetify')
const {connect} = require('react-redux')
const {getPending} = require('redux-submission')

const titleCase = require('title-case')
const Modal = require('boundless-modal').default

const ImageEditor = require('../components/image-editor')
const {Button, ToggleButton, IconButton} = require('../components/button')
const createDispatchers = require('../util/create-dispatchers')
const Mutator = require('../util/mutator')
const SignAttributeEditor = require('../components/sign-attribute-editor')

import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { lang } from 'moment';

const {
  fetchCategories,
  fetchSignConfig,
  showImageEditor,
//  uploadSignImage
} = require('../actions')

const {
  mutateCurrentDatapoint,
} = require('../actions/datapoint');

const className = sf('./sign.css')

module.exports = connect(
  function stateToProps (state, props) {
    const sign = state.currentDatapoint.signs[props.signIndex]

    return {
      sign: sign,
      signImageUrl: sign.imageUrl,
      imageEditorVisible: state.interpret.imageEditorVisible,
 //     uploadPending: getPending(uploadSignImage, state),
      priority: sign.priority,
      datapoint: state.currentDatapoint,
      categories: state.config.categories,
      signConfig: state.config.signConfigsByCategory[sign.category],
      signConfigsByCategory: state.config.signConfigsByCategory
      // categoriesPending: getPending(fetchCategories, state),
      // signConfigPending: getPending(fetchSignConfig, state)
    }
  },
  createDispatchers([
    mutateCurrentDatapoint,
    fetchSignConfig,
    showImageEditor,
  //  uploadSignImage
  ])
)(React.createClass({
  componentWillUpdate,
  componentWillMount,
  render
}))

function componentWillUpdate (nextProps) {
  const nextSign = nextProps.sign || {}
  const currentSign = this.props.sign || {}
//   if (nextSign.category && nextSign.category !== currentSign.category) {
// debugger
//     this.props.fetchSignConfig({
//       jurisdiction: nextProps.datapoint.jurisdiction,
//       category: nextSign.category
//     })
//   }
}

function componentWillMount () {
  // if (this.props.sign.category) {
  //   this.props.fetchSignConfig({
  //     jurisdiction: this.props.datapoint.jurisdiction,
  //     category: this.props.sign.category
  //   })
  // }
}

function render () {
  const props = this.props
  const pathToSign = ['signs', props.signIndex]
  const self = this

  const mutator = Mutator({
    path: pathToSign,
    onChange: (mutation) => props.mutateCurrentDatapoint(mutation)
  })

  if (!props.sign.notes) props.sign.notes = [];
  
  return h('div', {
    className: classes(className, 'pa3')
  }, [
    !self.props.imageEditorVisible ? undefined : h(Modal, {
      maskProps: {
        onClick: () => props.showImageEditor(!props.imageEditorVisible)
      }
    }, h(ImageEditor, {
      src: props.datapoint.rawSignUrl,
      pending: props.uploadPending,
      onClose: () => props.showImageEditor(false),
      // onCrop: ({meta, uri}) => props.uploadSignImage({
      //   uri,
      //   meta,
      //   sign: props.sign
      // })
    })),

    h('h2', {className: 'text-center'}, `SIGN ${props.signIndex + 1} DETAILS`),

    h('div', {
      className: 'flex justify-between ma2'
    }, [
      h('div', {
        key: 'image-preview',
        className: 'w-50 flex justify-center items-center sign-preview'
      }, !props.sign.imageUrl ? undefined : h('img', {src: props.signImageUrl})),

       h('div', {
         className: 'w-50 justify-left items-left'
       }, [
 //        h(Button, {
 //          onClick: () => props.showImageEditor(props.sign.imageUrl !== undefined)
 //        }, `${props.sign.imageUrl ? 'Replace Image' : 'Add Image'}`)
         // h(Dropzone, {
         //   onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiltes, rejectedFiles)
        //  },
       //   h('p', 'Change File'))
       ])
    ]),

    h('div', {className: 'flex justify-between items-center pa2'}, [
      h('div.b', 'Sign Category'),
      h(Select, {
        className: 'w-50',
        value: props.sign.category,
        placeholder: props.categoriesPending ? 'Loading...' : 'Select Category',
        clearable: false,
        options: props.categories.map((cat) => ({value: cat, label: cat})),
        onChange: (item) => {
          mutator.onSetValue(['category'], item.value)
          mutator.onSetValue(['attributes'], {}) // remove all set attributes when switching category
          mutator.onSetValue(['signConfig'], props.signConfigsByCategory[item.value].id)
        }
      })
    ]),

    h('div', {className: 'flex justify-between items-center pa2'}, [
      h('div.b', 'Direction'),
      h('div', [
        h(ToggleButton, {
          selected: props.sign.directionLeft,
          className: 'w4',
          onClick: () => mutator.onSetValue(['directionLeft'], !props.sign.directionLeft)
        }, h('i.fa.fa-arrow-left')),
        h(ToggleButton, {
          selected: props.sign.directionRight,
          className: 'w4',
          onClick: () => mutator.onSetValue(['directionRight'], !props.sign.directionRight)
        }, h('i.fa.fa-arrow-right'))
      ])
    ]),

    h('div', {className: 'flex justify-between pa2'}, [
      h('div.b', 'Notes'),
      h('div', {className: 'w-50'}, [
        ...props.sign.notes.map((note, index) => h('div.w-100.flex.justify-start mv2', [
          h('div.flex.items-center', {}, h(IconButton, {
            className: 'bg-red white mr2',
            onClick: () => {
              if (!window.confirm('Are you sure you want to remove this note?')) return
              mutator.onRemoveValue(['notes'], index)
            }
          }, 'x')),
          h('textarea', {
            value: note,
            className: 'flex-auto h3',
            onChange: (ev) => mutator.onSetValue(['notes', index], ev.target.value),
            maxLength: 100
          })
        ])),

        h(IconButton, {
          className: 'bg-blue white mv3',
          onClick: () => mutator.onPushValue(['notes'], '')
        }, '+')
      ])
    ]),

    ...renderAttributes() || []
  ])

  function renderAttributes () {
    if (!props.sign.category) return
    if (props.signConfigPending) return h('div', 'Loading Sign Config...')
    if (!props.signConfig) return

    return [
      h('div.b.mt2.ml2', titleCase(props.sign.category) + ' Attributes'),
      h(SignAttributeEditor, {
        sign: props.sign,
        signConfig: props.signConfig,
        onChange: (mutation) => { 

          let removalValue = mutation.path[mutation.path.length - 2];
          let removalPath = mutation.path.slice(0);
          if (removalPath.length <= 2) {
            removalPath = [];

            if (mutation.value == false) {
              return props.mutateCurrentDatapoint({
                type: 'remove',
                path: pathToSign.concat(['attributes'], removalPath),
                value: removalValue
              });
            }

            if ((Array.isArray(mutation.value)) && (mutation.value.length == 0)) {
              return props.mutateCurrentDatapoint({
                type: 'remove',
                path: pathToSign.concat(['attributes'], removalPath),
                value: removalValue
              });
            }

            if ((typeof mutation.value == 'string') && (mutation.value.length == 0)) {
              return props.mutateCurrentDatapoint({
                type: 'remove',
                path: pathToSign.concat(['attributes'], removalPath),
                value: removalValue
              });
            }
          }

          return props.mutateCurrentDatapoint({
            ...mutation,
            path: pathToSign.concat(['attributes'], mutation.path)
          })
        }
      })
    ]
  }

  function onDrop (acceptedFiles, rejectedFiles) {
    console.log("acceptedFiles = ", acceptedFiles);
    console.log("rejectedFiles = ", rejectedFiles);
  }
}
