const t = require('tcomb')
const Sign = require('./sign')
const JurisdictionRef = require('./jurisdiction-ref')

const STATUSES = ['DATA_COLLECTED', 'PREPROCESSED']

exports.STATUSES = STATUSES

exports.StatusType = t.enums.of(STATUSES, 'Datapoint.status')

exports.Type = t.struct({
  id: t.maybe(t.String),
  jurisdiction: JurisdictionRef.Type,
  notes: t.list(t.String),
  rawSignId: t.Number,
  rawSignUrl: t.String,
  isPublicPhoto: t.Boolean,
  latitude: t.Number,
  longitude: t.Number,
  signs: t.list(t.struct({
    priority: t.Number,
    sign: Sign.Type
  })),
  status: t.maybe(exports.StatusType)
}, {
  name: 'Datapoint',
  defaultProps: {
    notes: [],
    status: 'DATA_COLLECTED'
  }
})
