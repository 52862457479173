const t = require('tcomb')
const flatten = require('arr-flatten')

const SignConfig = require('../sign-config')
const Sign = require('../sign')
const Datapoint = require('../datapoint')
const SignAttrValue = require('../sign-attr-value')
const SignAttrConfig = require('../sign-attr-config')
const walkSignAttributes = require('../walk-sign-attributes')
const ValidationError = require('./validation-error')

/*
 * Given a datapoint and a dictionary of sign configs, will return an array of errors
 * found when validating the datapoint and all of its signs.
 */
module.exports = function validateDatapoint (datapoint, signConfigsById) {
  Datapoint.Type.is(datapoint)

  return flatten([
    ...datapoint.signs.map(validateSign)
  ])
    .filter(Boolean)

  function validateSign (sign, signIndex) {
    let signConfig = signConfigsById[sign.signConfig];

    // if not found, check for same category
    if (!signConfig) {
      let signConfigs = Object.keys(signConfigsById);
      signConfigs.forEach(signConfigId => {
        if (sign.category === signConfigsById[signConfigId].category) {
          sign.signConfig = signConfigsById[signConfigId].id;
          signConfig = signConfigsById[signConfigId];
        }
      });
    }

    const error = Sign.validate(sign)
    if (error) return error

    return flatten([
      ...walkSignAttributes(sign, signConfig, walkAttribute)
    ])
      .filter(Boolean)

    function walkAttribute ({path, attributeValue, attributeConfig, type, yieldChildren}) {
      const errorPath = ['signs', signIndex].concat(path)

      // If this item has a description, use that as the last item in the error path instead of the last key
      if (attributeConfig.description) {
        errorPath[errorPath.length - 1] = attributeConfig.description
      }

      const errors = type === 'Primitive'
            ? validateAttribute()
            : []

      return [
        ...errors
          .filter(Boolean)
          .map((error) => ValidationError(errorPath, error)),
        // Currently, no validation is done on array or structure nodes.
        ...yieldChildren ? yieldChildren() : []
      ]

      function validateAttribute () {
        var errors = []

        const ConfigSchema = getConfigAttributeSchema(attributeConfig)
        if (ConfigSchema && ConfigSchema.validateAttributeValue) {
          errors.push(ConfigSchema.validateAttributeValue(attributeConfig, attributeValue))
        }

        var ValueSchema = SignAttrValue[attributeConfig.type]
        if (ValueSchema && ValueSchema.validate) {
          errors.push(ValueSchema.validate(attributeValue))
        }

        return errors
      }
    }
  }
}

// TODO(ajoslin): Currently, there is no way to determine the type of an AttributeConfig besides duck typing.
// Solution: add `AttributeConfig.configType: String` to the backend.
// This is currently pending, and we use duck typing until then.
function getConfigAttributeSchema (config) {
  return ('maximumLength' in config && 'minimumLength' in config) ? SignAttrConfig.String
            : ('unit' in config && 'minimum' in config) ? SignAttrConfig.Number
            : ('format' in config) ? SignAttrConfig.DateTime
    : SignAttrConfig.Base
}
