const t = require('tcomb')

exports.Type = t.struct({
  type: t.enums.of('Selector'),
  value: t.String
}, {
  name: 'SignAttrValue.Selector',
  defaultProps: {
    type: 'Selector',
    value: '---'
  }
})

exports.hasValue = (data) => {
  return ((data.value) && (data.value !== ''));
}