module.exports = {
  area,
  areaIsLoading
}

function areaIsLoading(state = false, action) {
  switch (action.type) {
    case 'AREA_IS_LOADING':
      return action.isLoading

    default:
      return state
  }
}

function area(state = {}, action) {
  switch (action.type) {
    case 'FETCH_AREA_SUCCESS':
      return action.area

    case 'saveDatapoint':
      if (action.error) return state;
      let savedDP = action.payload;

      return {
        ...state,
        formattedDps: state.formattedDps.map(dp => {
          if (dp.rawSignId === savedDP.rawSignId) return savedDP;
          else return dp;
        })
      }

    case 'updateDatapointLocation':
      if (action.error) return state;

      return {
        ...state,
        formattedDps: state.formattedDps.map(dp => {
          if (dp.rawSignId === action.payload.rawSignId) {
            dp.latitude = action.payload.latitude;
            dp.longitude = action.payload.longitude;
          }
          return dp;
        }),
        dataPoints: state.dataPoints.map(dp => {
          if (dp.id === action.payload.rawSignId) {
            dp.locations.captured.latitude = action.payload.latitude;
            dp.locations.captured.longitude = action.payload.longitude;
          }
          return dp;
        })
      }


    case 'resetDatapoint':
      if (action.error) return state;
      savedDP = action.payload;

      return {
        ...state,
        formattedDps: state.formattedDps.map(dp => {
          if (dp.rawSignId === savedDP.rawSignId) return savedDP;
          else return dp;
        })
      }

    case 'SET_PROCESSING_SIDE':
      return {
        ...state,
        processingSide: action.direction
      }

    case 'AREA_TAG_CHANGED':
      return {
        ...state,
        tags: action.tags
      }

    case 'COPY_TAGS_ADD':
      let newState = {...state};
      if (!newState.copyTags) newState.copyTags = [];

      newState.copyTags.push(action.copyTag);
      return newState;

    case 'COPY_TAGS_DELETE':
      newState = {...state};
      if (!newState.copyTags) return newState;

      newState.copyTags = newState.copyTags.filter(tag => tag.name != action.copyTag);
      return newState;

    default:
      return state
  }
}
