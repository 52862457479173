const {createStore, applyMiddleware} = require('redux')
const {submissionMiddleware} = require('redux-submission')
const thunk = require('redux-thunk').default
const Logger = require('redux-logger')
const reducer = require('./reducers')

let state = {}
// state = JSON.parse(localStorage.state)

module.exports = function configureStore (initialState = state) {
  return createStore(
    reducer,
    initialState,
    applyMiddleware(
      thunk,
      submissionMiddleware,
      Logger()
    )
  )
}
