const t = require('tcomb')

exports.Type = t.struct({
  value: t.Boolean,
  type: t.enums.of('Boolean')
}, {
  name: 'SignAttrValue.Boolean',
  defaultProps: {
    value: false,
    type: 'Boolean'
  }
})

exports.hasValue = (data) => typeof data.value === 'boolean'
