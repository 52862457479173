const t = require('tcomb')
const h = require('react-hyperscript')
const {propTypes} = require('tcomb-react')

const Mutator = require('../../../util/mutator')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')

module.exports = NumberComponent

NumberComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Number.Type,
  data: SignAttrValue.Number.Type
})

function NumberComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  return h('div', rest, [
    h('div', {className: 'flex items-center justify-between'}, [
      h('div', 'Number Type'),
      h('div', {className: 'w-50'}, [
        h('input', {
          className: 'w-100',
          type: 'number',
          value: data.value,
          step: Number(config.stepSize || 1),
          min: Number(config.minimum || 0),
          max: Number(config.maximum || 999),
          onChange: (ev) => mutator.onSetValue(['value'], parseInt(ev.target.value))
        }),
        h('div', config.unit)
      ])
    ])
  ])
}
