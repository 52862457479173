const assert = require('assert')
const schemas = require('../schema')
const fetch = require('isomorphic-fetch')
const uriToBlob = require('dataurl-to-blob')
const SpotApi = require('./spot-api')
const Collection = require('./collection')

const methods = Collection({
  schema: schemas.Sign,
  Model: SpotApi.Sign,
  db: SpotApi.db
})

module.exports = {
  ...methods,
//  uploadSignImage
}

function uploadSignImage ({sign, uri} = {}) {
  assert.equal(typeof sign, 'object', 'object datapoint required')
  assert.equal(typeof uri, 'string', 'string uri expected')

  return SpotApi.Sign.fromAttr(SpotApi.db.serializer, sign)
    .signedImagePutUrl(SpotApi.db)
    .then(function (uploadUrl) {
      return fetch(uploadUrl, {
        body: uriToBlob(uri),
        method: 'PUT'
      })
    })
    .then(() => null) // there is no return value, the image url is already known.
    .catch(methods.parseError)
}
