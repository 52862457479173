const assert = require('assert')
const dotProp = require('dot-prop-immutable')

module.exports = function mutate (object, op) {
  const ops = {
    get,
    set,
    remove,
    push,
    orderedPush
  }

  assert(Array.isArray(op.path), 'array op.path required')
  assert(op.type in ops, 'op.type must be one of ' + Object.keys(ops).join('|') + ', got ' + op.type)
  assert('value' in op, 'op.value required')

  return ops[op.type](object, op.path, op.value)

  function get (object, path) {
    return dotProp.get(object, path)
  }
  
  function set (object, path, value) {
    return dotProp.set(object, path, value)
  }

  function remove (object, path, key) {
    return dotProp.delete(object, path.concat(key))
  }

  function push (object, path, value) {
    const array = dotProp.get(object, path) || []

    return dotProp.set(object, path, array.concat(value))
  }

  function orderedPush (object, path, value) {
    const array = dotProp.get(object, path) || []

    return dotProp.set(object, path, array.concat(value).sort())
  }
}
