var _ = require('lodash');
var moment = require('moment');


var exports = module.exports;

exports.stringify = function(range) {

  //console.log(range)
  if (!_.isObject(range)) return "";
  switch(range.subtype) {
    case 'STANDARD_RANGE':
      var output = moment().hour(range.startTime/60).minute(range.startTime % 60).format('HH:mm') + " - " +
        moment().hour(range.endTime/60).minute(range.endTime % 60).format('HH:mm');
      return output;
    default:
      return "";
  }
};
