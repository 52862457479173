const assert = require('assert')
const {validate} = require('tcomb-validation')
const HttpError = require('http-status-error')

const identity = (n) => n

module.exports = createCollection

function optionDefaults (options) {
  return {
    startAfter: 0,
    limit: 10,
    ...(options || {})
  }
}

function createCollection ({
  schema,
  Model,
  db,
  parse
}) {
  parse = parse || identity

  return {
    instantiate,
    parseSuccess,
    parseError,
    get,
    list,
    create,
    update
  }

  function instantiate (data) {
    data = data || {}
    const item = schema.Type(data)
    return schema.Type(Model.fromAttr(db.serializer, item))
  }

  function parseSuccess (response) {
    console.log('parseSuccess', response)
    return !response ? null
      : Array.isArray(response.items) ? response.items.map(parse)
      : parse(response)
  }

  function parseError (error) {
    error = error || {}
    const httpError = HttpError(error.status || 500)

    httpError.message = /failed to fetch/i.test(error.message || '') ? 'Please make sure you have an internet connection and try again.'
      : typeof error.message === 'string' ? error.message
      : typeof error === 'string' ? error
      : httpError.message

    return Promise.reject(error)
  }

  function get (options) {
    assert.equal(typeof options.id, 'string', 'string options.id required')
    return Model.get(db, options.id)
      .then(parseSuccess, parseError)
  }

  function list (options) {
    options = optionDefaults(options)
    return Model.list(db, options.startAfter, options.limit)
      .then(parseSuccess, parseError)
  }

  function create (payload) {
    var result = validate(payload, schema.Type)
    if (!result.isValid()) return Promise.reject(result.firstError())

    return Model.fromAttrs(db, payload).save()
      .then(parseSuccess, parseError)
  }

  function update (payload) {
    // return Promise.resolve(null)
    return create(payload)
  }
}
