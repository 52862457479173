const t = require('tcomb')
const h = require('react-hyperscript')
const {propTypes} = require('tcomb-react')
const Rating = require('react-rating');

const Mutator = require('../../../util/mutator')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')

module.exports = RatingComponent

RatingComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.Number.Type,
  data: SignAttrValue.Number.Type
})

function RatingComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  let fractions = Number(config.step) == 0 ? 1 : 1 / Number(config.step)
  
  return h('div', rest, [
    h('div', {className: 'flex items-center justify-between'}, [
      h('div', 'Rating Type'),
      h('div', {className: 'w-50'}, [
        h(Rating, {
          className: 'w-100',
          stop: Number(config.maximum || 5),
          start: Number(config.minimum || 0),
          initialRating: data.value,
          fractions,
          onChange: (rate) => mutator.onSetValue(['value'], parseInt(rate))
        })
      ])
    ])
  ])
}
