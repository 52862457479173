const t = require('tcomb')

exports.Type = t.struct({
  type: t.enums.of('Rating'),
  value: t.Number
}, {
  name: 'SignAttrValue.Rating',
  defaultProps: {
    type: 'Rating',
    value: 0
  }
})

exports.hasValue = (data) => {
  return Number(data.value) > 0
}