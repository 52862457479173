const t = require('tcomb')
const h = require('react-hyperscript')
const {propTypes} = require('tcomb-react')

const Mutator = require('../../../util/mutator')
const {SignAttrValue, SignAttrConfig} = require('../../../schema')

module.exports = HTMLStringComponent

HTMLStringComponent.propTypes = propTypes({
  className: t.maybe(t.String),
  onChange: t.Function,
  config: SignAttrConfig.String.Type,
  data: SignAttrValue.String.Type
})

function HTMLStringComponent ({
  path,
  config,
  data,
  onChange,
  ...rest
}) {
  const mutator = Mutator({
    path,
    onChange
  })

  return h('div', rest, [
    h('div', {className: 'flex items-center justify-between'}, [
      h('textarea', {
        className: `w-100 h${Math.round(config.maximumValue / 80)}`,
        value: data.value,
        onChange: (ev) => mutator.onSetValue(['value'], ev.target.value)
      })
    ])
  ])
}
