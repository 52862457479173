module.exports = {
  config
}

function config(state = {}, action) {
  switch (action.type) {
    case 'SET_JURISDICTIONS':
      return {
        ...state,
        jurisdictions: action.jurisdictions.reduce((acc, juris) => ({
          ...acc,
          [juris.id]: juris
        }), {})
      }

    case 'FETCH_AREA_SUCCESS':
      return {
        ...state,
        areaJurisdiction: calculateAreaJurisdiction(action.area, state.jurisdictions)
      }

    case 'SET_SIGN_CONFIGS':
      return {
        ...state,
        signConfigs: action.signConfigs,
        signConfigsByCategory: action.signConfigs.reduce((acc, sc) => ({
            ...acc,
            [sc.category]: sc
          }), {}),
        signConfigsById: action.signConfigs.reduce((acc, sc) => ({
            ...acc,
            [sc.id]: sc
          }), {}),
        categories: action.signConfigs.map(sc => sc.category)
      }

    default:
      return state
  }
}

function calculateAreaJurisdiction(area, jurisdictions) {
  const defaultJurisdiction = '809d50cd-1025-4569-91f5-e10cf8d64e47' // Australia
  if (!area.jurisdictions) return jurisdictions[defaultJurisdiction];

  let areaJurisdicitons = area.jurisdictions.map(jurisdiction => {
    for (var id in jurisdictions) {
      let j = jurisdictions[id];
      if (j.name === jurisdiction.longName) {
        return j;
      }
    }
    return jurisdictions[defaultJurisdiction];
  })
  
  return areaJurisdicitons.reduce((j, i, defaultJurisdiction) => {
    if (j) return j;
    else return jurisdictions[defaultJurisdiction];
  })
}