const { singular } = require('pluralize')
const {createActions} = require('redux-actions')
const api = require('../api')
const {validators} = require('../schema')
const uuidv4 = require("uuid/v4");

const identity = (n) => n

module.exports = createActions({
  addNewSignToCurrentDatapoint: identity,
  mutateCurrentDatapoint: identity,
  showImageEditor: identity,
  //uploadSignImage: api.sign.uploadSignImage,
  selectDatapoint: identity,
  setDatapointJurisdiction: identity,
  toggleMapOpen: identity,

  /**
   * Saves current, now processed, data point to the new database,
   * and then mark all signs associated with this data point in the old API as processed
   *
   * @param datapoint
   * @param signConfigByCategory
   * @param tags
   * @param isAreaStart
   * @returns {*}
   */
  saveDatapoint: (datapoint, signConfigsById, tags, isAreaStart) => {

    // if (!datapoint.signs.length) return Promise.reject([{message: 'Datapoint needs at least one sign'}]);
    const errors = validators.datapoint(datapoint, signConfigsById)
    if (errors.length) return Promise.reject(errors)

    // Formatting data point for PAM API
    const newDatapoint = { ...datapoint }
    delete newDatapoint.signs
    delete newDatapoint.status
    //delete newDatapoint.id
    delete newDatapoint.notes
    delete newDatapoint.preprocessedSigns
    newDatapoint.jurisdiction = datapoint.jurisdiction.id
    newDatapoint.zone = null
    newDatapoint.rawSignId = String(newDatapoint.rawSignId)

    // Create or update data point in PAM database
    return api.pam.saveDatapoint(newDatapoint).then((newDatapoint) => {
      datapoint.id = newDatapoint.id;
      datapoint.pamReferenceId = newDatapoint.id;
      datapoint.jurisdictionReferenceId = newDatapoint.jurisdiction;
      datapoint.status = 'interpreted';

      const datapointSignIds = datapoint.signs.map(sign => sign.id);
      const expiredSigns = (newDatapoint.signs || []).filter(sign => !datapointSignIds.includes(sign.id));
      datapoint.expiredSignIds = expiredSigns.map(sign => sign.id);

      return Promise.all(datapoint.signs.map((sign) => {
        // Formatting sign for PAM API
        const newSign = { ...sign }
        newSign.priority = sign.priority
        newSign.datapoint = newDatapoint.id
        delete newSign.category
        delete newSign.imageHash
        delete newSign.imageKeyOriginal
        delete newSign.rawSignId

        // Create or update sign in PAM database
        return api.pam.saveSign(newSign)
      }))
    })
    .then(() => {
      // Remove all expired signs
      return Promise.all(datapoint.expiredSignIds.map((signId) => {
        return api.pam.deleteSign(signId);
      }))
    })
    .then(() => {
      // Mark datapoint as processed in the DIT database
      if (isAreaStart) return api.dataCollection.updateArea(datapoint, 'started', tags)
      else return api.dataCollection.updateRawDatapoint(datapoint, tags);
    })
    .then(() => {
      return datapoint
    })
    .catch(error => {
      return Promise.reject(error)
    })
  },

  resetDatapoint: datapoint => {
    datapoint.status = 'signs-preprocessed';
    datapoint.pamReferenceId = null;
    return api.dataCollection.updateRawDatapoint(datapoint)
      .then(() => {
        window.location.reload(true);
        return datapoint;
      })
  },

  deleteDatapoint: datapointId => {
    return api.dataCollection.deleteRawDatapoint(datapointId)
      .then(() => {
        window.location.reload(true);
        return datapointId;
      });
  },

  insertDatapoint: datapointId => {
    return api.dataCollection.insertRawDatapoint(datapointId)
      .then(() => {
        window.location.reload(true);
        return datapointId;
      });
  },

  updateDatapointLocation: (datapoint, newLatitude, newLongitude) => {
    return api.dataCollection.updateRawDatapointLocation(datapoint, newLatitude, newLongitude)
    .then(() => {
      return { rawSignId: datapoint, latitude: newLatitude, longitude: newLongitude };
    });
  },

  updateDatapointPhoto: (datapoint, imageBase64Data) => {
    return api.dataCollection.updateRawDatapointPhoto(datapoint, imageBase64Data)
    .then( data => {
      window.location.reload(true);
      return datapoint;
    });
  }
})