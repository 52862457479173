const h = require('react-hyperscript')
const classes = require('classnames')

module.exports = ToggleButton

function ToggleButton ({selected, className, children, ...rest}) {
  return h('a', {
    className: classes(
      'dib pv2 pointer tc ba b--black-10',
      className || 'w3',
      selected ? 'bg-blue b--white white shadow-1' : 'bg-black-10'
    ),
    ...rest
  }, children)
}
