const t = require('tcomb')

exports.Type = t.struct({
  type: t.enums.of('Number'),
  value: t.Number
}, {
  name: 'SignAttrValue.Number',
  defaultProps: {
    type: 'Number',
    value: 0
  }
})

exports.hasValue = (data) => {
  if (typeof data.value === 'string') {
    if (data.value.substr(data.value.length - 1) === ".") {
      return true;
    }
  }
  return data.value === Number(data.value)
}