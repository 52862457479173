module.exports = {
  Boolean: require('./boolean'),
  DayRange: require('./day-range'),
  MonthRange: require('./month-range'),
  Number: require('./number'),
  String: require('./string'),
  TimeRange: require('./time-range'),
  TariffType: require('./tariff'),
  Rating: require('./rating'),
  Selector: require('./selector'),
  HTMLString: require('./html-string')
}
