const h = require('react-hyperscript')
const classes = require('classnames')

module.exports = Alert

Alert.DangerAlert = DangerAlert
Alert.SuccessAlert = SuccessAlert

function Alert ({className, children, ...rest}) {
  return h('div', {
    className: classes(className, 'pa3 mb1 ba b--black-50'),
    ...rest
  }, children)
}

function DangerAlert ({className, ...rest}) {
  return Alert({
    className: classes(className, 'bg-washed-red darkred'),
    ...rest
  })
}

function SuccessAlert ({className, ...rest}) {
  return Alert({
    className: classes(className, 'bg-washed-green darkgreen'),
    ...rest
  })
}
