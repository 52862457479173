module.exports = {
  schedule
}

function schedule(state = {}, action) {
  switch (action.type) {
    case 'SELECT_CURRENT_ZONE':
      return {
        ...state,
        currentZone: state.currentZone === action.zone ? null : action.zone,
        errorZone: null
      }
    
    case 'SELECT_ERROR_ZONE':
      return {
        ...state,
        currentZone: null,
        errorZone: state.errorZone === action.zone ? null : action.zone,
      }
      
    default:
      return state
  }
}