const assert = require('assert')
const mutate = require('./mutate')

module.exports = Mutator

Mutator.mutate = mutate

function Mutator ({path, onChange}) {
  path = path || []

  assert(Array.isArray(path), 'array path required')
  assert.equal(typeof onChange, 'function', 'function onChange required')

  return {
    onSetValue: (subPath, value) => onChange({
      path: path.concat(subPath),
      type: 'set',
      value
    }),
    onRemoveValue: (subPath, index) => {index >= 0 ? onChange({
      path: path.concat(subPath),
      type: 'remove',
      value: index
    }) : null},
    onPushValue: (subPath, value) => onChange({
      path: path.concat(subPath),
      type: 'push',
      value
    }),
    onOrderedPushValue: (subPath, value) => onChange({
      path: path.concat(subPath),
      type: 'orderedPush',
      value
    })
  }
}
