const h = require('react-hyperscript')
const Schemas = require('../../../schema')
const renderDescription = require('../../../util/render-description')
const PrimitiveValueComponents = require('../primitive-values')

module.exports = PrimitiveValue

function PrimitiveValue ({
  path,
  attributeConfig,
  attributeValue,
  onChange
}) {
  
  const PrimitiveComponent = PrimitiveValueComponents[attributeConfig.type]
  const Schema = Schemas.SignAttrValue[attributeConfig.type]

  if (!PrimitiveComponent) {
    return h('div', {className: 'ma2 darkred'}, `${attributeConfig.type} type values are not yet implemented.`)
  }

  // Default empty attributeValues (new signs) to empty version of this type
  attributeValue = Schema.Type(attributeValue || {})

  return h('div', [
    attributeConfig.description
      ? h('div', {className: 'b mb2'}, renderDescription(attributeConfig.description))
      : undefined,

    h(PrimitiveComponent, {
      config: attributeConfig,
      data: attributeValue,
      onChange: (mutation) => onChange({
        ...mutation,
        path: path.concat(mutation.path)
      })
    })
  ])
}
