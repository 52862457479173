const t = require('tcomb')
const Sign = require('./sign')

exports.Type = t.struct({
  priority: t.Number,
  sign: Sign.Type
}, {
  name: 'SignPriority',
  defaultProps: {
    priority: 0,
    sign: {}
  }
})
