const t = require('tcomb-validation')
const ValidationError = require('error/validation')
const castArray = require('cast-array')

module.exports = function validateSchema (value, Schema, options = {}) {
  var error
  var result

  result = t.validate(value, Schema.Type)
  if (!result.isValid()) {
    error = ValidationError(result.errors)
  }

  if (!error && Schema.validate) {
    result = Schema.validate(value, Schema.Type)
    if (result) {
      error = ValidationError(castArray(result))
    }
  }

  if (error && options.throw) {
    throw error
  }

  return error
}
