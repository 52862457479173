const t = require('tcomb')

exports.Type = t.struct({
  category: t.String,
  processingScripts: t.maybe(t.Object),
  description: t.Object,
  jurisdiction: t.Object,
  attributes: t.maybe(t.Object), // SignAttrConfig
  structures: t.maybe(t.Object) // SignConfigStructure
}, {
  name: 'SignConfig',
  defaultProps: {
    processingScripts: {},
    structures: {}
  }
})
