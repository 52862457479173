const api = require('../api')
const { formatDatapoint } = require('../util/format-legacy-api')
const { combineDatapoint } = require('../util/format-legacy-api')

const {fetchJurisdictionSignConfigs } = require('./config');
const { saveDatapoint } = require('./datapoint');
const { setCurrentZone } = require('./schedule');

module.exports = {
  fetchNextArea,
  fetchArea,
  areaLoading,
  fetchAreaSuccess,
  selectDatapoint,
  saveArea,
  regenerateArea,
  skipArea,
  setProcessingSide,
  saveProcessingSide,
  regeneratePaths,
  tagsChange,
  copyTagsAdd,
  copyTagsDelete
}

function fetchNextArea() {
  return (dispatch) => {
    return api.dataCollection.fetchAreasList().then(data => {
      dispatch({
        type: 'FETCH_AREA_LIST_SUCCESS',
        areaList: data.areas
      })
      return dispatch(fetchArea(data.areas[0].id))
    })
  }
}

function fetchArea(areaId) {
  return (dispatch, getState) => {
    dispatch(setCurrentZone(null));
    dispatch(areaLoading(true));

    api.dataCollection.fetchAreaDetail(areaId).then(areaDetails => {
      areaDetails = areaDetails.area;
      let dps = areaDetails.dataPoints;
      dps.unshift(areaDetails.start);
      dps.push(areaDetails.end);
      areaDetails.formattedDps = [];
      if (dps) areaDetails.formattedDps = dps.map(dp => formatDatapoint(dp));
      return areaDetails;
    })
    .then(fetchPamDatapoints)
    .then(fetchSignConfigs)
    .then(area => {
      dispatch(areaLoading(false));
      dispatch(fetchAreaSuccess(area))
      return area;
    })
    .then(area => {
      let selectedDp = area.processingSide === 'RIGHT'
        ? area.formattedDps[area.formattedDps.length - 1]
        : area.formattedDps[0];

      let jurisdictionId = undefined;
      if ((area.dataPoints.length) && (area.dataPoints[0].jurisdictionReferenceId)) jurisdictionId = area.dataPoints[0].jurisdictionReferenceId;
      dispatch(fetchJurisdictionSignConfigs(jurisdictionId)).then(() => {
        dispatch(selectDatapoint(selectedDp));
      });
      return area;
    }).then(area => {
      let areaStart = area.formattedDps[0];
      let areaEnd = area.formattedDps[area.formattedDps.length - 1];
      let { signConfigsById, areaJurisdiction } = getState().config;

      if (!areaStart.id) {
        areaStart.jurisdiction = areaJurisdiction;
        dispatch(saveDatapoint(areaStart, signConfigsById, area.tags));
      }
      if (!areaEnd.id) {
        areaEnd.jurisdiction = areaJurisdiction;
        dispatch(saveDatapoint(areaEnd, signConfigsById, area.tags));
      }
      return area;
    })
  }
}

function skipArea(area) {
  const areaId = area.start.id;
  return (dispatch) => {
    api.dataCollection.updateArea({rawSignId: areaId}, 'deferred', area.tags).then(() => {
      dispatch(goToNextArea());
    });
    api.dataCollection.resetZonesForArea(areaId);
  }
}

function regeneratePaths(areaReferenceId, processingSide, direction, offset) {
  return (dispatch) => {
    api.dataCollection.regenerateSnappedPoints(areaReferenceId, processingSide, direction, offset).then(() => {
    });
  }
}

function goToNextArea() {
  return (dispatch, getState) => {
    let currentArea = getState().area;
    let areaList = getState().interpret.areaList;
    let nextArea = areaList[0];
    for (let i = 0; i < areaList.length; i++) {
      if (areaList[i].id === currentArea.start.id) {
        nextArea = areaList[i+1];
        break;
      }
    }
    dispatch(fetchArea(nextArea.id));
  }
}

function areaLoading(state) {
  return {
    type: 'AREA_IS_LOADING',
    isLoading: state
  }
}

function fetchAreaSuccess(area) {
  return {
    type: 'FETCH_AREA_SUCCESS',
    area
  }
}

function fetchPamDatapoints(areaDetails) {
  let formattedDps = areaDetails.formattedDps;
  return Promise.all(formattedDps.map(dp => {
    return api.pam.fetchDatapointDetails(dp.id).then(pamDp => {
      return combineDatapoint(dp, pamDp);
    });
  })).then(dps => {
    areaDetails.formattedDps = dps;
    return areaDetails;
  });
}

function fetchSignConfigs(areaDetails) {
  let formattedDps = areaDetails.formattedDps;
  return Promise.all(formattedDps.map(dp => {
    return Promise.all(dp.signs.map(sign => {
      return api.pam.fetchSignConfig(sign.signConfig).then(signConfig => {
        if (signConfig) {
          sign.category = signConfig.category;
          // sign.signConfig = signConfig.id;
        }
        return sign;
      })
    })).then(signs => {
      dp.signs = signs;
      return dp;
    })
  })).then(dps => {
    areaDetails.formattedDps = dps;
    return areaDetails;
  })
}

function selectDatapoint(datapoint) {
  return {
    type: 'SELECT_DATAPOINT',
    datapoint
  }
}

function regenerateArea() {
  return (dispatch, getState) => {
    dispatch({
      type: 'REGENERATE_AREA_PENDING',
      bool: true
    });

    dispatch({
      type: 'REGENERATE_AREA_SUCCESS',
      bool: false
    });

    
    let { formattedDps: dps, areaReference, tags } = getState().area;

    let areaStart = dps[0];
    let ditAreaStart = {
      status: 'completed',
      rawSignId: areaStart.rawSignId,
      pamReferenceId: areaStart.id,
    };

    resaveDitDatapointsWithTags(dps, tags)
    .then(result => {
      dispatch(regenerateAreaComplete(areaReference, ditAreaStart, tags));
    });

  };
}

function saveArea() {
  return (dispatch, getState) => {
    dispatch({
      type: 'SAVE_AREA_PENDING',
      bool: true
    });

    dispatch({
      type: 'SAVE_AREA_SUCCESS',
      bool: false
    });

    let zones = getState().zones;
    let { formattedDps: dps, processingSide, tags } = getState().area;

    let PAMdps = dps.reduce((acc, dp) => {
      acc[dp.rawSignId] = dp.id;
      return acc;
    }, {});
    zones = zones.map(zone => {
      return {
        ...zone,
        processingSide,
        datapoints: zone.datapoints.map(dp => PAMdps[dp])
      }
    });
    zones = zones.filter(zone => !zone.error)
    zones = zones.map(({datapoints, localJurisdiction, schedule, processingSide, zoneType, additionalTypeDetail, name}) => ({
      datapoints, localJurisdiction, schedule, processingSide, zoneType, additionalTypeDetail, name
    }));

    Promise.all(zones.map(zone => {
      return api.pam.saveZone(zone).then(pamZone => {
        zone.id = pamZone.id
        return zone;
      });
    })).then(zones => {
      addZonesToDatapoints(zones, dps);

      return Promise.all([
        resavePamDatapointsWithZones(zones, dps),
        resaveDitDatapointsWithZones(zones, dps, tags)
      ])
      .catch(error => {
        console.log('could not save datapoints in area', error);
        throw error;
      })
      .then(() => dps);
    })
    .catch(error => {
      return {error};
    })
    .then(dps => {
      if (dps.error) {
        return dispatch(saveAreaError());
      }

      let areaStart = dps[0];
      let ditAreaStart = {
        status: 'completed',
        rawSignId: areaStart.rawSignId,
        pamReferenceId: areaStart.id,
        zoneReferenceIds: areaStart.zones
      }
      dispatch(markAreaComplete(ditAreaStart, tags));
    })
  }
}

function addZonesToDatapoints(zones, dps) {
  zones.forEach(zone => {
    zone.datapoints.forEach(dpId => {
      let datapoint = dps.find(dp => dp.id === dpId);
      if (!datapoint.zones) datapoint.zones = [zone.id];
      else datapoint.zones.push(zone.id);
    })
  });
}

function resavePamDatapointsWithZones(zones, dps) {
  return Promise.all(dps.map(dp => {
    let pamDP = {
      ...dp,
      datazones: dp.zones,
      jurisdiction: dp.jurisdiction.id
    };
    delete pamDP.zones;
    delete pamDP.preprocessedSigns;
    delete pamDP.processingSigns;
    delete pamDP.signs;
    return api.pam.saveDatapoint(pamDP);
  }));
}

function resaveDitDatapointsWithZones(zones, dps, tags) {
  return Promise.all(dps.map(dp => {
    let ditDP = {
      status: 'interpreted',
      rawSignId: dp.rawSignId,
      pamReferenceId: dp.id,
      zoneReferenceIds: dp.zones
    };
    return api.dataCollection.updateRawDatapoint(ditDP, tags);
  }));
}

function resaveDitDatapointsWithTags(dps, tags) {
  return Promise.all(dps.map(dp => {
    let ditDP = {
      status: 'interpreted',
      rawSignId: dp.rawSignId,
      pamReferenceId: dp.id
    };
    return api.dataCollection.updateRawDatapoint(ditDP, tags);
  }));
}

function saveAreaError() {
  return (dispatch) => {
    dispatch({
      type: 'SAVE_AREA_PENDING',
      bool: false
    });

    dispatch({
      type: 'SAVE_AREA_SUCCESS',
      bool: false
    });
  }
}

function saveAreaSuccess() {
  return (dispatch) => {
    dispatch({
      type: 'SAVE_AREA_PENDING',
      bool: false
    });

    dispatch({
      type: 'SAVE_AREA_SUCCESS',
      bool: true
    });
  }
}

function regenerateAreaSuccess() {
  return (dispatch) => {
    dispatch({
      type: 'REGENERATE_AREA_PENDING',
      bool: false
    });

    dispatch({
      type: 'REGENERATE_AREA_SUCCESS',
      bool: true
    });
  }
}
function regenerateAreaError() {
  return (dispatch) => {
    dispatch({
      type: 'REGENERATE_AREA_PENDING',
      bool: false
    });

    dispatch({
      type: 'REGENERATE_AREA_SUCCESS',
      bool: false
    });
  }
}

function regenerateAreaComplete(areaReference, areaStart, tags) {
  return (dispatch) => {
    api.dataCollection.updateArea(areaStart, 'completed', tags).then(res => {
      if (res.area) {
          api.dataCollection.regenerateZones(areaReference).then(res => {
            dispatch(regenerateAreaSuccess());
            if (confirm('Area successfully saved! Continue to next area?')) {
              dispatch(goToNextArea());
            }    
          })
          .catch(error => {
            dispatch(regenerateAreaError());
          });
      }
      else {
        dispatch(regenerateAreaError());
      }
    })
    .catch(error => {
      dispatch(regenerateAreaError());
    });
  }
}

function markAreaComplete(areaStart, tags) {
  return (dispatch) => {
    api.dataCollection.updateArea(areaStart, 'completed', tags).then(res => {
      if (res.area) {
        dispatch(saveAreaSuccess());
        if (confirm('Area successfully saved! Continue to next area?')) {
          dispatch(goToNextArea());
        }
      }
      else {
        dispatch(saveAreaError());
      }
    })
  }
}

function setProcessingSide(direction) {
  return {
    type: 'SET_PROCESSING_SIDE',
    direction
  }
}

function saveProcessingSide(direction) {
  return (dispatch, getState) => {
    let { area } = getState()
    let { status, tags } = area

    dispatch({
      type: 'SAVE_PROCESSING_SIDE_PENDING',
      bool: true
    })

    let updateObj = {
      rawSignId: area.start.id,
      processingSide: direction
    }

    api.dataCollection.updateArea(updateObj, status, tags).then(() => {
      dispatch({
        type: 'SAVE_PROCESSING_SIDE_SUCCESS',
        bool: true
      })
    })
  }
}

function tagsChange(tags) {
  return (dispatch, getState) => {
    dispatch({
      type: 'AREA_TAG_CHANGED',
      tags
    });
  }
}

function copyTagsAdd(tag) {
  return (dispatch) => {
    dispatch({
      type: 'COPY_TAGS_ADD',
      copyTag: tag
    });
  }
}

function copyTagsDelete(tag) {
  return (dispatch) => {
    dispatch({
      type: 'COPY_TAGS_DELETE',
      copyTag: tag
    });
  }
}