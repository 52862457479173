const t = require('tcomb')

exports.Type = t.struct({
  id: t.maybe(t.String),
  category: t.maybe(t.String),
  directionLeft: t.Boolean,
  directionRight: t.Boolean,
  attributes: t.dict(t.String, t.Object), // SignAttrValue
  notes: t.list(t.String),
  imageHash: t.maybe(t.String),
  // signedImagePutUrl: t.maybe(t.String),
  imageKeyOriginal: t.maybe(t.String),
  imageUrl: t.maybe(t.String)
}, {
  name: 'Sign',
  defaultProps: {
    directionLeft: false,
    directionRight: false,
    attributes: {},
    notes: []
  }
})

exports.validate = function validateSign (sign) {
  if (!sign.category) {
    return new Error('Sign must have a category selected.')
  }
}
