const h = require('react-hyperscript');
import Select from 'react-select';
import {ModalContainer, ModalDialog} from 'react-modal-dialog';
const {connect} = require('react-redux');

const { IconButton, Button } = require('../../components/button')
const ImageEditor    = require('../../components/image-editor')
const DefinitionList = require('../../components/definition-list')
const MapArea        = require('../../components/map-area')
import Dropzone from 'react-dropzone';
import { isBuffer } from 'util';

const {updateDatapointLocation, updateDatapointPhoto} = require('../../actions/datapoint');
const {setProcessingSide, saveProcessingSide} = require('../../actions/area');

let messageEventListener = null;

function handleMessageEvent (event, props) {
  if ((((handleMessageEvent.previousEvent || {}).data || {}).command === event.data.command) && (((handleMessageEvent.previousEvent || {}).data || {}).valueAsJSONString == JSON.stringify((event.data || {}).value))) {
    return; // IGNORE
  }

  switch (event.data.source) {
    case undefined:
    case "react-devtools-bridge":
      return;
    case "spotmap":
      switch (event.data.command) {
        case "set-new-position":
          handleMessageEvent.previousEvent = { data : { command: event.data.command, valueAsJSONString: JSON.stringify(event.data.value) } };
          props.updateDatapointLocation(event.data.value.rawDataPointId, event.data.value.latitude, event.data.value.longitude);
          break;
        case "set-processing-side":
          handleMessageEvent.previousEvent = { data : { command: event.data.command, valueAsJSONString: JSON.stringify(event.data.value) } };
          props.setProcessingSide(event.data.value.processingSide);
          props.saveProcessingSide(event.data.value.processingSide);
        default: 
          break;
      }
      break;
    default: 
      break;
  }
}

function renderDatapoint (props) {
  function onDrop(acceptedFiles, rejectedFiles) {
    for (let i = 0; i < acceptedFiles.length; i++) {
      var reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[i]); 
      reader.onloadend = function() {
        var base64data = reader.result;    
        base64data = base64data.substring("data:image/jpeg;base64,".length);            
        props.updateDatapointPhoto(props.datapoint.rawSignId, base64data, props.onRawSignUrlUpdate);
      }
    }
  }

  window.removeEventListener("message", (e) =>  handleMessageEvent(e, props));
  window.addEventListener("message", (e) => handleMessageEvent(e, props));

  const spotMapPath = 'https://spotmap.spotparking.com.au';
  //const spotMapPath = 'http://localhost:3000';

  if (!messageEventListener) 
  return h('div', {
    className: 'pa2 w-50 flex-auto flex-column flex ba b--black datapoint-container'
  }, [
    h(DefinitionList, {
      className: 'pa2',
      items: [
        {
          label: 'Raw Sign ID',
          value: h('div', [props.datapoint.rawSignId, ' ', h(IconButton, {
            className: `bg-${props.datapoint.isPublicPhoto ? 'blue' :'green'} white`,
            onClick: () => {
              props.mutateCurrentDatapoint({
                path: ['isPublicPhoto'],
                type: 'set',
                value: !props.datapoint.isPublicPhoto
              })
            }
          }, props.datapoint.isPublicPhoto ? `Public Photo` : `Private Photo`)])
        }, 
        {
          label: 'Address',
          value: h('div', [props.address, ' ',
            h(IconButton, {
            className: 'bg-blue white',
            onClick: () => props.onToggleMapOpen()
          }, 'Map')])
        },
        {
          label: 'Jurisdiction',
          value: !props.datapoint.jurisdiction ? 'Loading...' : h(Select, {
            disabled: true,
            className: 'w-50',
            clearable: false,
            placeholder: 'Choose a jurisdiction...',
            value: props.datapoint.jurisdiction.id,
            options: Object.keys(props.jurisdictionById).map((id) => ({
              value: id,
              label: props.jurisdictionById[id].name
            })),
            onChange: ({value}) => {
              if (window.confirm('Changing jurisdiction will reset all datapoints in area. Continue?')) {
                // TODO: RESET ALL DATA
              }
              // props.mutateCurrentDatapoint({
              //   path: ['jurisdiction'],
              //   type: 'set',
              //   value: props.jurisdictionById[value]
              // })
            }
          })
        },
        {
          label: 'Notes',
          value: h('div', [
            (props.datapoint.notes || []).map((note, index) => h('div.flex.items-center', [
              h('div', null, h(IconButton, {
                className: 'bg-red white',
                onClick: () => {
                  if (!window.confirm('Are you sure you want to remove this note?')) return
                  props.mutateCurrentDatapoint({
                    path: ['notes'],
                    type: 'remove',
                    value: index
                  })
                }
              }, 'x')),
              h('textarea', {
                className: 'w-50 h3',
                value: note || '',
                onChange: (ev) => props.mutateCurrentDatapoint({
                  path: ['notes', index],
                  type: 'set',
                  value: ev.target.value
                })
              })
            ])),

            h(IconButton, {
              className: 'bg-blue white mt3',
              onClick: () => props.mutateCurrentDatapoint({
                path: ['notes'],
                type: 'set',
                value: (props.datapoint.notes || []).concat('')
              })
            }, '+')
          ])
        }
      ]
    }),

    h(ImageEditor, {
      src: props.datapoint.rawSignUrl,
      viewOnly: true
    }),
     h(Dropzone, {
       accept: ['image/jpeg'],
       onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)
     }, h('p', 'Click here to change image')),

    props.mapOpen && h(ModalContainer, {
      onClose: () => props.onToggleMapOpen()
    }, 
    h(ModalDialog, {
      style: { height: '90%', width: '90%'},
      onClose: () => props.onToggleMapOpen()
    },
    [
      h('iframe', {
        src: `${spotMapPath}/map?raw-datapoint-id=${props.currentDatapoint.rawSignId}&area-id=${props.area.areaReference}` + (props.area.processingSide ? `&processing-side=${props.area.processingSide}` : ''),
        title: "Spot Map",
        allowFullScreen: true,
        height: '100%',
        width: '100%'
      })
    //   h(MapArea, {
    //   location: { lat: props.datapoint.latitude, lng: props.datapoint.longitude },
    //   points: props.points || [],
    //   currentDatapoint: props.datapoint,
    //   updateDatapointLocation: props.updateDatapointLocation,
    //   mutateCurrentDatapoint: props.mutateCurrentDatapoint,
    // }),
  ]))
  ])
}

function mapStateToProps(state, props) {

  return {
    area: state.area,
    isLoading: state.areaIsLoading,
    currentDatapoint: state.currentDatapoint,
    zones: state.zones,
    unsaved: state.interpret.unsaved,
    currentZone: state.schedule.currentZone,
    areaSavePending: state.interpret.areaSavePending,
    isSavingProcess: state.interpret.saveProcessingSidePending,
    isSavedProcess: state.interpret.saveProcessingSideSuccess,
    calcZonesPending: state.interpret.calcZonesPending,
    selectedZone: state.schedule.currentZone,
    errorZone: state.schedule.errorZone
  };
}

function mapDispatchToProps(dispatch) {
  return ({
    updateDatapointLocation: (id, latitude, longitude) => dispatch(updateDatapointLocation(id, latitude, longitude)),
    updateDatapointPhoto: (id, imageBase64Data) => dispatch(updateDatapointPhoto(id, imageBase64Data)),
    setProcessingSide: direction => dispatch(setProcessingSide(direction)),
    saveProcessingSide: direction => dispatch(saveProcessingSide(direction)),
 /*   fetchNextArea: () => dispatch(fetchNextArea()),
    fetchArea: areaId => dispatch(fetchArea(areaId)),
    selectDatapoint: dp => dispatch(selectDatapoint(dp)),
    fetchAllJurisdictions: () => dispatch(fetchAllJurisdictions()),
    saveArea: () => dispatch(saveArea()),
    skipArea: area => dispatch(skipArea(area)),
    setCurrentZone: zone => dispatch(setCurrentZone(zone)),
    setProcessingSide: direction => dispatch(setProcessingSide(direction)),
    saveProcessingSide: direction => dispatch(saveProcessingSide(direction)),
    calculateZones: area => dispatch(calculateZones(area)),
    regeneratePaths: (areaId, processingSide, direction) => dispatch(regeneratePaths(areaId, processingSide, direction)), */
  })
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(renderDatapoint);